
import './ScrollMenu.less';
// import { GrVmMaintenance } from 'react-icons/gr';
import { FormattedMessage } from 'react-intl';
import playBtn from '../../../assets/images/icon-hotgames-play.png';
import infoBtn from '../../../assets/images/icon-hotgames-info.png';
import { Button, Popover } from 'antd';
import Marquee from 'react-fast-marquee';

interface Props {
    itemId: string;
    selected: boolean;
    onClick: Function;
    title?: string;
    img: any;
    content?: any;
    maintenance?: boolean;
    comingSoon?: boolean;
    showRibbon?: boolean;
    fromProviderMenu?: boolean;
    specialTag?: string;
    hasHoverCover?: boolean;
    hasTitleInfo?: boolean;
    infoContent?: any;
    fromMoreGames?: boolean;
    isNew?: boolean;
}

const ScrollMenuCard = ({ itemId, selected, onClick, title, img, content, maintenance = false, comingSoon = false, showRibbon = true, fromProviderMenu = false, specialTag, hasHoverCover = false, hasTitleInfo = false, infoContent = <></>, fromMoreGames = false, isNew = false }: Props) => {
    return (
        <div
            onClick={() => onClick()} // NOTE: for center items
            role="button"
            tabIndex={0}
            className={(maintenance || comingSoon) ? "scroll-menu-card-unavailable" : selected ? "scroll-menu-card" : "scroll-menu-card-disabled"}
        >
            <div className="scroll-menu-content">
                {(maintenance || comingSoon || isNew) && showRibbon ?
                    <div className={maintenance ? 'scroll-menu-maintenance-ribbon' : isNew ? 'scroll-menu-new-ribbon' : 'scroll-menu-coming-soon-ribbon'}>
                        {maintenance ?
                            <Marquee
                                speed={10}
                                pauseOnHover
                            >
                                {
                                    <div className='maintenance-marquee'><FormattedMessage id={'MAINTENANCE'} /></div>
                                }
                            </Marquee>
                            :
                            <FormattedMessage id={isNew ? 'NEW_PROVIDER' : 'COMING_SOON'} />
                        }
                    </div> : specialTag && showRibbon ?
                        <div className={'scroll-menu-special-tag-ribbon'}>
                            {specialTag}
                        </div> :
                        <></>
                }

                <img alt={title ?? ''} className={fromMoreGames ? 'mg-scroll-menu-img' : title ? 'scroll-menu-img' : fromProviderMenu ? 'scroll-menu-img-no-title' : 'scroll-menu-img'} src={img} draggable={false} />
                {hasHoverCover &&
                    <div className="blur">
                        <img className='play-btn' src={playBtn} alt='' />
                    </div>
                }
                {
                    !hasTitleInfo ?
                        <>
                            {title &&
                                <div className="scroll-menu-title">{title}</div>
                            }
                        </> : <div className='scroll-menu-title-with-info'>
                            <div className="scroll-menu-title">{title}</div>
                            <Popover placement="topLeft" content={infoContent} trigger="hover">
                                <Button><img className='scroll-menu-title-info' src={infoBtn} alt='info' /></Button>
                            </Popover>
                        </div>
                }
                {
                    content &&
                    content
                }
            </div>
        </div>
    );
}

export default ScrollMenuCard;
