import { FormattedMessage } from 'react-intl';
import '../GeneralFooter.less';

import Facebook from '../../../../assets/images/ftr_social_fb_ov.png';
import Youtube from '../../../../assets/images/icon-socialmedia-youtube.svg';
import Instagram from '../../../../assets/images/icon-socialmedia-instagram.svg';
import Line from '../../../../assets/images/icon-socialmedia-line.svg';
import Telegram from '../../../../assets/images/ftr_social_telegram_ov.png';
import Whatsapp from '../../../../assets/images/icon-whatsapp-clear.png'
import { useLayout } from '../../../../hooks/layout/LayoutContext';

const FooterSocialMedia = () => {
    const { layoutState } = useLayout();

    return (
        <div className='footer-social-media'>
            <div className='footer-social-media-item'>
                <div className='footer-social-media-icons'>
                    {layoutState.currency === 'THB' &&
                        <div className='footer-provider-items'>
                            <img src={Line} alt="line" className="provider-icon" />
                        </div>}
                    {layoutState.currency === 'SGD' && <>
                        <div className='footer-provider-items'>
                            <img src={Telegram} alt="telegram" className="provider-icon" />
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Whatsapp} alt="whatsapp" className="provider-icon" />
                        </div>
                    </>
                    }
                    <div className='footer-provider-items'>
                        <img src={Youtube} alt="instagram" className="provider-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Instagram} alt="youtube" className="provider-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Facebook} alt="line" className="provider-icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterSocialMedia;