
import "./HeaderMobile.less";
import { Drawer, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import iconHome from '../../../assets/images/icon-menu-home.svg';
import iconAffiliate from '../../../assets/images/icon-menu-affiliate.svg';
import iconPromos from '../../../assets/images/icon-menu-promos.svg';
import iconLive from '../../../assets/images/icon-menu-livescore.svg';
import iconAboutUs from '../../../assets/images/icon-menu-aboutus.svg';
import iconInfo from '../../../assets/images/icon-menu-infocentre.svg';
import iconTNC from '../../../assets/images/icon-menu-terms.svg';
import iconContactUs from '../../../assets/images/icon-menu-contactus.svg';
import iconLogout from '../../../assets/images/icon-menu-logout.svg';
import LangThaiFlag from '../../../assets/images/language-thailand.png';
import LangEngFlag from '../../../assets/images/language-uk.png';
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOGOUT } from "../../../pages/auth/auth.gql";
import { useEffect, useState } from "react";
import { CLEAR_CURRENT_USER, SET_DEFAULT_CURRENCY, SET_LOGIN_STATUS, useAuth } from "../../../hooks/auth/AuthContext";
import { clearAllTokens } from "../../helpers/set-token-cookie.helper";
import { SET_CURRENCY, SET_LANGUAGE, useLayout } from "../../../hooks/layout/LayoutContext";
import useSpinner from "../../../hooks/layout/useSpinner";
import HeaderLanguageDropdown from "../../components/header/HeaderElements/HeaderLanguageDropdown";
import { formatUrlCurrencyLang } from "../../helpers/general-helpers";

interface Props {
    isDrawerVisible: boolean;
    setIsDrawerVisible: any;
}

const HeaderMobileDrawer = ({ isDrawerVisible, setIsDrawerVisible }: Props) => {
    const navigate = useNavigate();
    const { setLoading } = useSpinner();
    const { layoutState, layoutDispatch } = useLayout();
    const { authState, authDispatch } = useAuth();
    const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [languageModalVisible, setLanguageModalVisible] = useState(false);

    const setDisplayLanguageFlag = () => {
        switch (layoutState.locale) {
            case 'en':
                return LangEngFlag;
            case 'th':
                return LangThaiFlag;
            default:
                return LangEngFlag;
        }
    }

    const onClose = () => {
        setIsDrawerVisible(false);
    };

    useEffect(() => {
        if (logoutLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutLoading])

    useEffect(() => {
        if (logoutData && logoutData.logout) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            navigate(`/`);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutData])

    const onMenuItemClick = (menu: string) => {
        setIsDrawerVisible(false);
        switch (menu) {
            case 'home':
                navigate('/')
                break;
            case 'affiliate':
                let url = '';
                let hostName = window.location.hostname;
                if (hostName.indexOf('localhost') >= 0 || hostName.indexOf('bo-ib8.net') >= 0)
                    url = 'https://stg-ib8-affiliate.firebaseapp.com';
                else {
                    hostName = hostName.replace('www.', '');
                    url = `https://aff.${hostName}`;
                }
                window.open(url);
                break;
            case 'promo':
                navigate('promotions');
                break;
            case 'aboutus':
                navigate('about-us');
                break;
            case 'info':
                break;
            case 'tnc':
                navigate('terms-and-conditions');
                break;
            case 'contactus':
                navigate('contact-us');
                break;
            case 'logout':
                logout();
                break;
            case 'language':
                setLanguageModalVisible(true);
                break;
            case 'faq':
                navigate('faq');
                break;
            case 'live-score':
                navigate('live-score');
                break;
        }
    }

    const handleCancel = () => {
        setLanguageModalVisible(false);
    }

    const onChangeLanguage = (language: string) => {
        localStorage.setItem('pfxl', language);
        layoutDispatch({ type: SET_LANGUAGE, payload: language });
        setLanguageModalVisible(false);
    }

    const onLanguageClick = (language: string, currency: string) => {
        localStorage.setItem('pfxl', language);
        localStorage.setItem('pfxc', currency);
        layoutDispatch({ type: SET_LANGUAGE, payload: language });
        layoutDispatch({ type: SET_CURRENCY, payload: currency });
        authDispatch({ type: SET_DEFAULT_CURRENCY, payload: currency });
        setLanguageModalVisible(false);
    }

    return (
        <>
            <Drawer width={300} placement="right" onClose={onClose} visible={isDrawerVisible}>
                <div className='mobile-menu-item-first' onClick={() => onMenuItemClick('home')}>
                    <img src={iconHome} alt="promotion" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_HOME' /></div>
                </div>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('promo')}>
                    <img src={iconPromos} alt="promotion" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_PROMOS' /></div>
                </div>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('live-score')}>
                    <img src={iconLive} alt="promotion" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_LIVE' /></div>
                </div>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('contactus')}>
                    <img src={iconContactUs} alt="contact us" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_CONTACT_US' /></div>
                </div>
                {/* {layoutState.currency === 'THB' && */}
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('affiliate')}>
                    <img src={iconAffiliate} alt="affiliate" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_AFFILIATE' /></div>
                </div>
                {/* } */}
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('faq')}>
                    <img src={iconInfo} alt="FAQ" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_FAQ' /></div>
                </div>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('aboutus')}>
                    <img src={iconAboutUs} alt="about us" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_ABOUT_US' /></div>
                </div>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('tnc')}>
                    <img src={iconTNC} alt="TNC" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_TNC' /></div>
                </div>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('language')}>
                    <img className="header-language-img" src={setDisplayLanguageFlag()} alt="language" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_REGION' /></div>
                </div>
                {authState.isLogin &&
                    <div className='mobile-menu-item' onClick={() => onMenuItemClick('logout')}>
                        <img src={iconLogout} alt="logout" className="footer-mobile-icon" />
                        <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_LOGOUT' /></div>
                    </div>
                }
            </Drawer>
            <Modal
                className="m-drawer-language-modal"
                visible={languageModalVisible}
                closable={true}
                footer={null}
                onCancel={handleCancel}
                destroyOnClose
            >
                <HeaderLanguageDropdown containerName={'language-dropdown-mobile'} onLanguageClick={onLanguageClick} />
            </Modal>
        </>
    );
};

export default HeaderMobileDrawer;
