
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { setDBLanguageToGet } from "../../../shared/helpers/language.helpers";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { useNavigate } from "react-router";
import { useLazyQuery } from "@apollo/client";
import { GET_BANNER_PROMOTION } from "../Home.gql";
import { useEffect, useState } from "react";
import { Modal, Spin } from "antd";

const Banner = ({ bannerData }: any) => {
    const { layoutState } = useLayout();
    const navigate = useNavigate();
    const [getBannerPromotion, { data: bannerPromotionData, loading: bannerPromotionLoading }] = useLazyQuery(GET_BANNER_PROMOTION, { fetchPolicy: 'cache-and-network' });
    const [bannerPromo, setBannerPromo] = useState<any>();
    const [modalVisible, setModalVisible] = useState(true);

    useEffect(() => {
        if (bannerPromotionData) {
            setBannerPromo(bannerPromotionData?.bannerPromotion);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannerPromotionData]);

    const bannerNavigateClick = (banner: any) => {
        if (banner?.redirectUrl?.redirectTypeId !== 'EXTERNAL') {
            if (banner?.redirectUrl?.redirectTypeId === 'PROMO_MODAL') {
                getBannerPromotion({ variables: { id: banner?.linkedModalId } });
                setModalVisible(true);
            } else {
                navigate(banner?.redirectUrl?.url)
            }
        }
    }

    return (
        <div className="home-banner">
            {//bannerData && bannerData.length > 0 ?
                <Swiper
                    initialSlide={1}
                    autoplay={true}
                    grabCursor={true}
                    slidesPerView={1}
                    spaceBetween={0}
                    slidesPerGroup={1}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={false}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    {bannerData && bannerData.banners?.map((banner: any) => {
                        return (
                            <SwiperSlide key={banner.id}>
                                <div className="banner-container" key={banner.id} onClick={() => { bannerNavigateClick(banner) }}>
                                    <img alt={banner.title} className="carousel-image"
                                        src={JSON.parse(banner?.pictureUrl)[setDBLanguageToGet(layoutState.locale)]} />
                                </div>
                            </SwiperSlide>

                        );
                    })}
                </Swiper>
                //:
                // <Spin />
            }
            {bannerPromo &&
                <Modal
                    className="promo-content-modal"
                    visible={modalVisible}
                    closable={true}
                    footer={null}
                    onCancel={() => { setModalVisible(false) }}
                    destroyOnClose>
                    {bannerPromotionLoading ? <Spin /> :
                        bannerPromo && bannerPromo?.editorHTMLContent &&
                        <div className="promo-content-container" dangerouslySetInnerHTML={{
                            __html: JSON.parse(bannerPromo?.editorHTMLContent)[setDBLanguageToGet(layoutState.locale)]
                        }} />
                    }
                </Modal>
            }
        </div>
    )
}

export default Banner;