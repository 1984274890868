import { gql } from "@apollo/client";

export const GET_PROMOTIONS = gql`
query Promotions($currency:String!){
    promotions(currency:$currency){
        promotions{
            title,
            description,
            displayFrom,
            displayTo,
            banner,
            thumbnail,
            sequence,
            editorHTMLContent,
            isActive,
            Bonus {
                id,
                bonusType {
                    id
                },
            },
            category,
        },
        promotionCategories{   
            id,
            name,
            sequence,
            createdAt
        }
    }
}
`

export const GET_PROMOTION_CATEGORIES = gql`
query PromotionCategories{
    promotionCategories{   
            id,
            name,
            sequence,
            createdAt
    }
}
`

export const GET_PROMOTION_THUMBNAILS = gql`
query PromotionThumbnails($currency:String!){
    promotionThumbnails(currency:$currency){
        promotions{
            title,
            thumbnail,
        },
    }
}
`

