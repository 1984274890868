import { FormattedMessage, useIntl } from "react-intl";
import '../GeneralFooter.less';

import Bank1 from '../../../../assets/images/icon-bank-01.svg';
import Bank2 from '../../../../assets/images/icon-bank-02.svg';
import Bank3 from '../../../../assets/images/icon-bank-03.svg';
import Bank4 from '../../../../assets/images/icon-bank-04.svg';
import Bank5 from '../../../../assets/images/icon-bank-05.svg';
import Bank6 from '../../../../assets/images/icon-bank-06.svg';
import Bank7 from '../../../../assets/images/icon-bank-07.svg';
import Bank8 from '../../../../assets/images/icon-bank-08.svg';
import Bank9 from '../../../../assets/images/icon-bank-09.svg';


import Bank10 from '../../../../assets/images/icon-dashboard-deposit-bank-21.png';
import Bank11 from '../../../../assets/images/icon-dashboard-deposit-bank-22.png';
import Bank12 from '../../../../assets/images/icon-dashboard-deposit-bank-23.png';
import Bank13 from '../../../../assets/images/icon-dashboard-deposit-bank-24.png';
import Bank14 from '../../../../assets/images/icon-dashboard-deposit-bank-25.png';
import Bank15 from '../../../../assets/images/icon-dashboard-deposit-bank-26.png';
import Bank16 from '../../../../assets/images/icon-dashboard-deposit-bank-27.png';
import Bank17 from '../../../../assets/images/icon-dashboard-deposit-bank-28.png';

import Payment1 from '../../../../assets/images/icon-footer-01.png';
import Payment2 from '../../../../assets/images/icon-footer-02.png';
import Payment3 from '../../../../assets/images/icon-footer-03.png';
import Payment5 from '../../../../assets/images/icon-footer-05.png';
import Payment7 from '../../../../assets/images/icon-footer-07.png';
import Payment8 from '../../../../assets/images/icon-footer-08.png';
import Payment9 from '../../../../assets/images/icon-footer-09.png';
import Payment10 from '../../../../assets/images/icon-footer-10.png';
import Payment11 from '../../../../assets/images/icon-footer-11.png';


import Payment12 from '../../../../assets/images/surepay.svg';
import Payment13 from '../../../../assets/images/fastpay.png';

import Payment14 from '../../../../assets/images/Gcash.svg';
import Payment15 from '../../../../assets/images/paymaya.png';

import { useLayout } from "../../../../hooks/layout/LayoutContext";

const FooterPaymentMethod = () => {
    const intl = useIntl();
    const { layoutState } = useLayout();

    return (
        <div className='footer-second-row'>
            {(layoutState.currency === 'THB' || layoutState.currency === 'SGD') && <>
                <div className='footer-provider-title'><FormattedMessage id='FOOTER_SUPPORTED_BANKS' /></div>
                <div className='footer-provider-items-list'>
                    {layoutState.currency === 'THB' && <>
                        <div className='footer-provider-items'>
                            <img src={Bank1} alt={intl.formatMessage({ id: 'FOOTER_BANK_BANGKOK' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_BANGKOK' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank2} alt={intl.formatMessage({ id: 'FOOTER_BANK_T' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_T' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank3} alt={intl.formatMessage({ id: 'FOOTER_BANK_KRUNGSI' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_KRUNGSI' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank4} alt={intl.formatMessage({ id: 'FOOTER_BANK_KRUNGTHAI' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_KRUNGTHAI' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank5} alt={intl.formatMessage({ id: 'FOOTER_BANK_SCB' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_SCB' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank6} alt={intl.formatMessage({ id: 'FOOTER_BANK_KIATNAKIN' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_KIATNAKIN' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank7} alt={intl.formatMessage({ id: 'FOOTER_BANK_TMB' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_TMB' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank8} alt={intl.formatMessage({ id: 'FOOTER_BANK_KASIKORN' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_KASIKORN' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank9} alt={intl.formatMessage({ id: 'FOOTER_BANK_UOB' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_UOB' /></div> */}
                        </div>
                    </>
                    }
                    {layoutState.currency === 'SGD' && <>
                        <div className='footer-provider-items'>
                            <img src={Bank10} alt={intl.formatMessage({ id: 'FOOTER_BANK_BANGKOK' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_BANGKOK' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank11} alt={intl.formatMessage({ id: 'FOOTER_BANK_T' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_T' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank12} alt={intl.formatMessage({ id: 'FOOTER_BANK_KRUNGSI' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_KRUNGSI' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank13} alt={intl.formatMessage({ id: 'FOOTER_BANK_KRUNGTHAI' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_KRUNGTHAI' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank14} alt={intl.formatMessage({ id: 'FOOTER_BANK_SCB' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_SCB' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank15} alt={intl.formatMessage({ id: 'FOOTER_BANK_KIATNAKIN' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_KIATNAKIN' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank16} alt={intl.formatMessage({ id: 'FOOTER_BANK_TMB' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_TMB' /></div> */}
                        </div>
                        <div className='footer-provider-items'>
                            <img src={Bank17} alt={intl.formatMessage({ id: 'FOOTER_BANK_KASIKORN' })} className="provider-icon" />
                            {/* <div className='footer-provider-item-txt'><FormattedMessage id='FOOTER_BANK_KASIKORN' /></div> */}
                        </div>
                    </>
                    }
                </div>
            </>
            }
            <div className='footer-provider-title'><FormattedMessage id='FOOTER_PAYMENT_METHOD' /></div>
            <div className='footer-provider-items-list'>
                {layoutState.currency === 'THB' && <>
                    {/* <div className='footer-provider-items'>
                    <img src={Payment1} alt="bitcoin" className="provider-icon" />
                </div>
                <div className='footer-provider-items'>
                    <img src={Payment2} alt="usdt tether" className="provider-icon" />
                </div>
                <div className='footer-provider-items'>
                    <img src={Payment3} alt="ethereum" className="provider-icon" />
                </div> */}
                    <div className='footer-provider-items'>
                        <img src={Payment5} alt="eezipay" className="payment-method-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Payment7} alt="help2pay" className="payment-method-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Payment8} alt="tpay" className="payment-method-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Payment9} alt="vader pay" className="payment-method-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Payment10} alt="true money wallet" className="payment-method-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Payment11} alt="payment gateway" className="payment-method-icon" />
                    </div>
                </>
                }
                {layoutState.currency === 'SGD' && <>
                    <div className='footer-provider-items'>
                        <img style={{ width: "100%" }} src={Payment12} alt="eezipay" className="payment-method-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Payment13} alt="help2pay" className="payment-method-icon" />
                    </div>
                </>
                }
                {layoutState.currency === 'PHP' && <>
                    <div className='footer-provider-items'>
                        <img style={{ width: "100%" }} src={Payment14} alt="gcash" className="payment-method-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img style={{ width: "100%" }} src={Payment15} alt="paymaya" className="payment-method-icon" />
                    </div>
                </>
                }
            </div>
        </div>
    )
}

export default FooterPaymentMethod;