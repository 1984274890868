import { Collapse, Menu } from 'antd';
import { useAuth } from '../../../../hooks/auth/AuthContext';
import '../GeneralHeader.less';
import { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus, AiOutlinePlusCircle } from 'react-icons/ai';
import NumberFormat from 'react-number-format';

const { Panel } = Collapse;

const HeaderWalletDropdown = () => {
    const { authState } = useAuth();
    const [walletDropdownClassName, setWalletDropdownClassName] = useState('dashboard-tansfer-wallet-menu');

    const menuItemClick = () => {
        setWalletDropdownClassName('dashboard-tansfer-wallet-menu');
    }

    return (
        <>
            <Menu
                className={walletDropdownClassName}
                mode='vertical'>
                <Collapse
                    accordion
                    expandIcon={({ isActive }) => isActive ? <AiOutlineMinus /> : <AiOutlinePlus />}
                    expandIconPosition="right"
                    bordered={false}>
                    {authState?.walletCategory && authState?.walletCategory.length > 0 &&
                        authState?.walletCategory.map((walletCategory: { seq: number; labelName: string; productName: string; bal: number }) => {
                            return <Panel key={walletCategory.productName} header={
                                <>
                                    <div className='transfer-collapse-panel-header'>
                                        <div>{walletCategory.productName}</div>
                                        <div>
                                            <NumberFormat value={walletCategory.bal} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />
                                        </div>
                                    </div>
                                </>}>
                                {authState?.wallets && authState?.wallets?.filter((x: { userWalletType: { displayProductId: string; id: string }; }) => walletCategory.productName === 'MAIN' ? x.userWalletType?.id === 'MAIN' || x.userWalletType?.id === 'BONUS' : x.userWalletType?.id === walletCategory.productName) &&
                                    authState?.wallets?.filter((x: { userWalletType: { displayProductId: string; id: string }; }) => walletCategory.productName === 'MAIN' ? x.userWalletType?.id === 'MAIN' || x.userWalletType?.id === 'BONUS' : x.userWalletType?.displayProductId === walletCategory.productName).map((wallet: { userWalletType: { displayName: string; }; id: string; name: string; balance: number; }) => {
                                        return <div key={wallet.id ?? 'default'} className='transfer-child-menu' onClick={menuItemClick}>
                                            <div className='transfer-child-menu-name'>{wallet.userWalletType.displayName} </div>
                                            <div className='transfer-child-menu-right-elements'>
                                                <div className='transfer-child-menu-balance'>
                                                    <NumberFormat value={wallet.balance} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} />
                                                </div>
                                                <div className='transfer-child-menu-icon'>
                                                    <AiOutlinePlusCircle />
                                                </div>
                                            </div>
                                        </div>

                                    })
                                }
                            </Panel>
                        })
                    }
                </Collapse>
            </Menu>
        </>
    )
}

export default HeaderWalletDropdown;