


import { Modal } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLayout } from '../../../../hooks/layout/LayoutContext';
import { setDBLanguageToGet } from '../../../helpers/language.helpers';
import './RewardsModal.less';

interface Props {
    modalVisible: any;
    onClose: any;
    name: any;
    currency: any;
    amount: any;
    detailContainerName: any;
    content: string;
}

const RewardsModal = ({ detailContainerName, modalVisible, onClose, name, currency, amount, content }: Props) => {
    const { layoutState } = useLayout();
    return (
        <>
            <Modal
                className={'rewards-modal'}
                visible={modalVisible}
                closable={true}
                footer={null}
                onCancel={onClose}
                destroyOnClose
            >
                <div className='red-packet-img'>
                    <div className={detailContainerName}>
                        <div className='user-name'>{name}</div>
                        <div className='packet-amt'>
                            <FormattedMessage id={`CURRENCY_SYMBOL_${currency ?? 'THB'}`} />
                            {amount}
                        </div>
                        <div className='congratulations'>
                            {content ? JSON.parse(content)[setDBLanguageToGet(layoutState.locale)] : ''}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default RewardsModal;