

import "../HomeMobile.less";
import { FormattedMessage } from "react-intl";
import { useAuth } from "../../../../hooks/auth/AuthContext";
import { checkIsGameAvailable, checkIsGameComingSoon, checkIsGameUnderMaintenance } from "../../../../shared/helpers/general-helpers";
interface Props {
    game: any;
    onGameClick: any;
}

const MobileGameListRow = ({ game, onGameClick }: Props) => {
    const { authState } = useAuth();

    return (

        <div key={game.id} className={checkIsGameAvailable(game, authState?.maintenanceList ?? []) ? `home-mobile-game-list-item ${game.mobileBgClass}` : `home-mobile-game-list-item-inactive ${game.mobileBgClass}`}
            onClick={checkIsGameAvailable(game, authState?.maintenanceList ?? []) ? () => { onGameClick(game) } : () => { }}>
            <div className="home-mobile-game-list-left">
                <img src={game.mobileLogo ? game.mobileLogo : game.logo} alt={game.label} className="game-list-img-left" />
                <div className="game-list-bet-btn">
                    <FormattedMessage id='M_HOME_GAME_CARD_BET_BUTTON' />
                </div>
            </div>
            <div className="home-mobile-game-list-right">
                <div className={
                    checkIsGameAvailable(game, authState?.maintenanceList ?? [])
                        ? "coming-soon-txt-inactive" :
                        checkIsGameComingSoon(game) ? "coming-soon-txt" :
                            "maintenance-txt"} >
                    <FormattedMessage id={checkIsGameUnderMaintenance(game, authState?.maintenanceList ?? []) ? 'MAINTENANCE' : 'COMING_SOON'} />
                </div>
                {/* <img src={game.image} alt={game.label} className="game-list-img-right" /> */}
            </div>
        </div>

    );
};

export default MobileGameListRow;
