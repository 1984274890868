import "./HomeMobile.less";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import useSpinner from "../../../hooks/layout/useSpinner";
import HeaderLoginMobile from "../../../shared/_mobile/header/HeaderLoginMobile";
import { formatUrlCurrencyLang } from "../../../shared/helpers/general-helpers";

const HomeAnonAuth = () => {
    const { setLoading } = useSpinner();
    const { authState } = useAuth();
    const navigate = useNavigate();
    const [totalBalance, setTotalBalance] = useState<number>(0);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [loginModalVIsible, setLoginModalVisible] = useState(false);
    const [walletDropdownClassName, setWalletDropdownClassName] = useState('dashboard-tansfer-wallet-menu');

    const register = () => {
        navigate(`register`);
    }

    const login = () => {
        setLoginModalVisible(true);
    }

    return (
        <>
            <div className="home-anon-container">
                <div className="home-anon-register" onClick={register}><FormattedMessage id='LOGIN_JOIN_BTN' /></div>
                <div className="home-anon-login" onClick={login}><FormattedMessage id='LOGIN_LOGIN_BTN' /></div>
            </div>
            <HeaderLoginMobile modalVisible={{ visible: loginModalVIsible, setVisible: setLoginModalVisible }} setLoading={setLoading} />
        </>
    );
};

export default HomeAnonAuth;
