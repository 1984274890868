import UpcomingIcon from '../../../assets/images/icon-upcomingmatches.png';
import { GET_CMD_LIVE_MATCH } from "../../provider-game-page/Game.gql";
import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import useSpinner from '../../../hooks/layout/useSpinner';
import { forEach } from 'lodash';
import moment from 'moment';
import HomeMobileUpcomingSwiper from '../../_mobile/home/HomeMobileUpcomingSwiper';
import HomeUpcomingSwiper from './Home-Upcoming-Swiper';

interface Props {
    showBtn: boolean;
}

const HomeMobileUpcomingMatches = ({ showBtn }: Props) => {
    const { setLoading } = useSpinner();
    const [upcomingMatchesList, setupcomingMatchesList] = useState([]);
    const { data: liveMatchData } = useQuery(GET_CMD_LIVE_MATCH,
        { fetchPolicy: "no-cache" });

    useEffect(() => {
        if (liveMatchData && liveMatchData.matches) {
            let matchesList: any = [];
            forEach(liveMatchData.matches, (match) => {
                matchesList.push({
                    id: match.matchID,
                    title: match.leagueID,
                    imgUrl: '',
                    url: "CMD",
                    hasUrl: true,
                    gameCode: "",
                    tournament: match.leagueName,
                    gameDate: moment(match.matchDate).utcOffset(8).format('DD-MMMM-YYYY'), //'2-October-2022',
                    gameTime: moment(match.matchDate).utcOffset(8).format('hh.mma') + ' (GMT+8)', //'11.00pm (GMT+8)',
                    homeLogo: match.homeImgUrl,
                    homeName: match.homeTeamName,
                    homeOdd: match.homeOdds,
                    awayLogo: match.awayImgUrl,
                    awayName: match.awayTeamName,
                    awayOdd: match.awayOdds,
                    gameOdd: match.odds,
                    providerId: 'CMD'
                })
            });

            setupcomingMatchesList(matchesList);
        } else {
            setupcomingMatchesList([]);
        }

    }, [liveMatchData])

    return (
        <HomeUpcomingSwiper moreBtn={showBtn} titleIcon={UpcomingIcon} titleString='HOME_UPCOMING_TITLE' gameList={upcomingMatchesList} />
    )
}

export default HomeMobileUpcomingMatches;