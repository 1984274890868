import { FormattedMessage } from 'react-intl';
import '../GeneralFooter.less';

import Security_1 from '../../../../assets/images/iovation.png';
import Security_2 from '../../../../assets/images/threat-metrix.svg';

const FooterSecurity = () => {
    return (
        <div className='footer-third-row'>
            <div className='footer-third-row-item-left'>
                <div className='footer-third-row-item-title'>
                    <FormattedMessage id='FOOTER_SECURITY' />
                </div>
                <div className='footer-third-row-icons'>
                    <div className='footer-provider-items'>
                        <img src={Security_1} alt="certification bmm" className="certification-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Security_2} alt="certification itech lab" className="certification-icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterSecurity;