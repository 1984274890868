
import { FormattedMessage } from 'react-intl';
import '../GeneralFooter.less';
import licenseLogo from '../../../../assets/images/ftr_curacao.png';
import minAgeLogo from '../../../../assets/images/18over.png';

const FooterCopyRight = () => {
    return (
        <div className='footer-forth-row'>
            <div className='footer-provider-title'><FormattedMessage id='FOOTER_GAMING_LICENSE' /></div>
            <img className='license-logo' src={licenseLogo} alt='license' />
            <div className='license-description'>
                <FormattedMessage id='FOOTER_LICENSE_DETAIL' />
            </div>
            <div className='footer-forth-row-copyright'>
                <FormattedMessage id='FOOTER_COPYRIGHT' />
                &copy;
                <FormattedMessage id='FOOTER_COPYRIGHT_CONTENT' values={{
                    year: new Date().getFullYear().toString()
                }} />
                <img className='age-logo' src={minAgeLogo} alt='license' />
            </div>
        </div>
    )
}

export default FooterCopyRight;