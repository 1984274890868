import { gql } from '@apollo/client';


export const GET_BANNER = gql`
query Banners($currency:String!){
    banners(currency:$currency){
        id,
        title,
        pictureUrl,
        redirectUrl {
          url,
          redirectTypeId
        },
        linkedModalId
    }
  }
`;

export const GET_BANNER_PROMOTION = gql`
query BannerPromotion($id:String!){
  bannerPromotion(id:$id){
        id,
        title,
        editorHTMLContent
    }
  }
`;