
import "./Home.less";
import { useLazyQuery, useQuery } from '@apollo/client';
import MarqueeComponent from "./home-components/Marquee";
import Banner from "./home-components/Banner";
import GameCard from "./home-components/Game-Card";
import HomeMidSection from "./home-components/Home-Mid-Section";
import BottomSectionCarousels from "./home-components/Bottom-Section-Carousels";
import { GET_BANNER } from "./Home.gql";
import WCCountdown from "./home-components/WCCountdown";
import { SET_REFERRAL_MODAL_SHOW, useAuth } from "../../hooks/auth/AuthContext";
import { useEffect, useState } from "react";
import enRefImg from '../../assets/images/referral-popup-en.png';
import thRefImg from '../../assets/images/referral-popup-th.png';
import { useLayout } from "../../hooks/layout/LayoutContext";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { GET_HOME_POPUP_BANNER } from "../../shared/gql/profile.gql";
import { setDBLanguageToGet } from "../../shared/helpers/language.helpers";
import { announcements } from "../../shared/components/top-menu/TopMenuList";
import HomeSportsContainer from "./home-components/HomeSportsContainer";
import HomePromos from "./home-components/Home-Promos";
import { sgdCasinoList, sgdMoreGameList, thbCasinoList, thbMoreGameList } from "./home-components/Home-More-Games-Lists";
import BottomSectionCarouselsCasino from "./home-components/Bottom-Sections-Carousels-Casino";
import { useIntl } from "react-intl";
import { formatSEOCountry } from "../../shared/helpers/general-helpers";
import { Helmet } from "react-helmet";

const Home = () => {
    // const { data: bannerData } = useQuery(GET_BANNER, { variables: { currency: authState?.defaultCurrency }, fetchPolicy: 'cache-and-network' });
    const [getBanner, { data: bannerData }] = useLazyQuery(GET_BANNER, { fetchPolicy: 'cache-and-network' });
    const { id } = useParams<{ id: string }>();
    const [activeTabKey, setActiveTabKey] = useState<string>(id ?? '');
    const { authState, authDispatch } = useAuth();
    const [referralModalVisible, setReferralModalVisible] = useState(false);
    const { layoutState } = useLayout();
    const { data } = useQuery(GET_HOME_POPUP_BANNER, { fetchPolicy: 'no-cache' });
    const [homePopUpBannerData, setHomePopUpBannerData] = useState<any>();
    const intl = useIntl();
    const [title, setTitle] = useState(intl.formatMessage({ id: 'SEO_TITLE_HOME' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }));
    const [desc, setDesc] = useState(intl.formatMessage({ id: 'SEO_DESC_HOME' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }));

    useEffect(() => {
        setTitle(intl.formatMessage({ id: 'SEO_TITLE_HOME' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }));
        setDesc(intl.formatMessage({ id: 'SEO_DESC_HOME' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }))
    }, [localStorage.getItem('pfxl')])

    useEffect(() => {
        if (data && data?.homePopUpBanner && data?.homePopUpBanner.length > 0) {
            setHomePopUpBannerData(data?.homePopUpBanner[0]);
        }
    }, [data])

    useEffect(() => {
        if (authState?.defaultCurrency) {
            getBanner({
                variables: { currency: authState?.defaultCurrency }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState?.defaultCurrency]);


    const closeReferralModal = () => {
        authDispatch({ type: SET_REFERRAL_MODAL_SHOW, payload: false });
        setReferralModalVisible(false);
    }

    useEffect(() => {
        if (authState.showReferralModal) {
            setReferralModalVisible(true);
        }
    }, []);

    const onReferralClick = () => {
        closeReferralModal();
    }

    const marqueeData = {
        announcement: [{
            id: '1',
            message: announcements,
        }]
    }

    // useEffect(() => {
    //     if (authState.showReferralModal) {
    //         setReferralModalVisible(true);
    //     }
    // }, []);

    // const closeReferralModal = () => {
    //     authDispatch({ type: SET_REFERRAL_MODAL_SHOW, payload: false });
    //     setReferralModalVisible(false);
    // }

    // const onReferralClick = () => {
    //     setActiveTabKey('9');
    //     closeReferralModal();
    // }

    return (
        <div className="home-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={desc} />
            </Helmet>
            <Banner bannerData={bannerData} />
            {/* <WCCountdown /> */}
            <MarqueeComponent marqueeData={marqueeData} />
            {/* <GameCard /> */}
            <HomePromos />
            <HomeSportsContainer />
            <HomeMidSection />
            <BottomSectionCarousels sgdList={sgdMoreGameList} thbList={thbMoreGameList} label={'POPULAR_GAMES'} />
            <BottomSectionCarouselsCasino sgdList={sgdCasinoList} thbList={thbCasinoList} label={'BEST_CASINO'} />
            {/* <News /> */}
            {
                homePopUpBannerData?.pictureUrl &&
                <Modal
                    className="referral-modal"
                    visible={referralModalVisible}
                    closable={true}
                    footer={null}
                    onCancel={closeReferralModal}
                    destroyOnClose
                >
                    {homePopUpBannerData?.pictureUrl &&
                        <img onClick={onReferralClick} alt='referral' src={homePopUpBannerData?.pictureUrl ?
                            JSON.parse(homePopUpBannerData?.pictureUrl)[setDBLanguageToGet(layoutState.locale)] : enRefImg} />
                    }
                </Modal>
            }
        </div>
    );
};

export default Home;
