import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { useNavigate } from "react-router-dom";
import useSpinner from "../../../hooks/layout/useSpinner";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { GET_GAME_URL } from "../../provider-game-page/Game.gql";
import { checkIsGameAvailable, checkIsIOSLineApp } from "../../../shared/helpers/general-helpers";
import { FormattedMessage, useIntl } from "react-intl";
import MobileTransferModal from "../../../shared/_mobile/modals/MobileTransferModal";
import HeaderLoginMobile from "../../../shared/_mobile/header/HeaderLoginMobile";
import HomeSectionTitle from "../../home/home-components/Home-Section-Title";
import useNotification from "../../../hooks/layout/useNotification";
import HomeMobileUpcomingMatchCard from "../../_mobile/home/HomeMobileUpcomingMatchCard";
import LoginModal from "../../../shared/login_modal/LoginModal";
import '../Home.less';
import HomeUpcomingMatchCard from "./Home-Upcoming-Match-Card";

SwiperCore.use([Autoplay]);

interface Props {
    gameList: any;
    titleIcon: any;
    titleString: any;
    showTitle?: boolean;
    moreBtn?: boolean;
}

const HomeUpcomingSwiper = ({ gameList, titleIcon, titleString, showTitle = true, moreBtn = false }: Props) => {
    const navigate = useNavigate();
    const { setLoading } = useSpinner();
    const { authState } = useAuth();
    const [loginModalVIsible, setLoginModalVisible] = useState(false);
    const [getGameUrl, { data: gameData, loading: gameLoading, error: gameError }] = useMutation(GET_GAME_URL,
        { fetchPolicy: "no-cache" });
    const [selectedGameUrl, setSelectedGameUrl] = useState<any>();
    const [transferModalVisible, setTransferModalVisible] = useState(false);
    const [selectedGame, setSelectedGame] = useState<any>();
    const [popupWindow, setPopupWindow] = useState<Window>();
    const { setErrorNotification } = useNotification();
    const intl = useIntl();

    useEffect(() => {
        setLoading(gameLoading); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameLoading])


    useEffect(() => {
        if (gameData && gameData.gameUrl && gameData.gameUrl.url) {
            if(gameData.gameUrl.isLineBrowser) {
                window.location.replace(gameData.gameUrl.url);
            } else {
                if (popupWindow) {
                    popupWindow.location.href = gameData.gameUrl.url;
                    //window.open(gameData.gameUrl.url, "gameTab");
                }
            }

            // setSelectedGameUrl(gameData.gameUrl.url);
            //setTransferModalVisible(true);
        } else {
            // if (popupWindow) {
            //     popupWindow.close();
            //     setErrorNotification(intl.formatMessage({ id: `ERROR_GENERAL` }));
            // }
        }
    }, [gameData]);

    const onGameClick = (game: any) => {
        setSelectedGame(game);
        // if (authState.isLogin) {
        //     setSelectedGame(game);
        //     game.hasUrl ? setTransferModalVisible(true) : onMenuItemClick(game.url);
        // } else
        if (authState.isLogin)
            game.hasUrl ? onMenuRedirectClick(game.url, game.gameCode) : onMenuItemClick(game.url);
        else
            setLoginModalVisible(true);
    }

    const onMenuItemClick = (provider: string) => {
        navigate(provider);
    }

    const onMenuRedirectClick = (providerCode: string, gameCode: string) => {
        if (!checkIsIOSLineApp()) {
            const promptWindow = window.open('', "_blank");
            if (promptWindow)
                setPopupWindow(promptWindow);
        }

        getGameUrl({
            variables: {
                gameUrlInput: {
                    gameProviderCode: providerCode,
                    platform: 'web',
                    lang: intl.locale,
                    isFreeGame: 'false',
                    gameCode
                }
            }
        });
    }

    const openGame = () => {
        if (selectedGameUrl) {
            window.open(selectedGameUrl, "gameTab");
        }
    }

    return (
        <>
            <div className="home-live-casino-container">
                {/* <div className='home-live-casino-title-container'>
                    <HomeSectionTitle showTitle={showTitle} icon={titleIcon} title={titleString} isH1={true} />
                    {
                        moreBtn &&

                        <div className='home-live-casino-more' onClick={() => { }}>
                            <FormattedMessage id={'HOME_MORE_GAMES_TITLE'} />
                        </div>
                    }
                </div> */}
                <div className='home-upcoming-carousel-container'>
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={0}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {gameList.map((game: any) => {
                            return (
                                <SwiperSlide key={game.id}>
                                    <div key={game.id}>
                                        {
                                            game.imgUrl ?
                                                <img alt={game.title} className={checkIsGameAvailable(game, authState?.maintenanceList ?? []) ? "carousel-image" : "carousel-image-inactive"} src={game.imgUrl} />
                                                :
                                                <HomeUpcomingMatchCard onGameClick={onGameClick} game={game} />
                                        }
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
            <LoginModal modalVisible={{ visible: loginModalVIsible, setVisible: setLoginModalVisible }} />
            {/* <MobileTransferModal providerId={selectedGame?.providerId} game={selectedGame} onMenuRedirectClick={onMenuRedirectClick} modalVisible={{ visible: transferModalVisible, setVisible: setTransferModalVisible }} /> */}
        </>
    )
}

export default HomeUpcomingSwiper;