

import '../../DashboardMobile.less';

import iconDeposit from '../../../../../assets/images/icon-deposit.png';
import iconTransfer from '../../../../../assets/images/icon-transfer.png';
import iconWithdraw from '../../../../../assets/images/icon-withdraw.png';
import iconHistory from '../../../../../assets/images/icon-history.png';
import iconRebate from '../../../../../assets/images/icon-instant-rebate-white.svg';
import DashboardMobileTopButton from './DashboardMobileTopButton';
import { useNavigate } from 'react-router-dom';
import { formatUrlCurrencyLang } from '../../../../../shared/helpers/general-helpers';

interface Props {
    setActiveTabKey: any;
    isFromMainPage?: boolean;
}

const DashboardMobileTopButtons = ({ setActiveTabKey, isFromMainPage = false }: Props) => {
    const navigate = useNavigate();

    const changeTab = (key: string) => {
        navigate(`/${formatUrlCurrencyLang()}/dashboard/banking/${key}`);
    }

    return (
        <div className="m-dashboard-wallets-buttons-container">
            <DashboardMobileTopButton icon={iconDeposit} text={'DASHBOARD_DEPOSIT'} changeTab={() => changeTab("deposit")} keys={"1"} />
            <DashboardMobileTopButton icon={iconTransfer} text={'DASHBOARD_TRANSFER'} changeTab={() => changeTab("transfer")} keys={"2"} />
            <DashboardMobileTopButton icon={iconWithdraw} text={'DASHBOARD_WITHDRAW'} changeTab={() => changeTab("withdrawal")} keys={"3"} />
            <DashboardMobileTopButton icon={iconHistory} text={'DASHBOARD_HISTORY'} changeTab={() => changeTab("history/bettingSummary")} keys={"4"} />
            {!isFromMainPage &&
                <DashboardMobileTopButton icon={iconRebate} text={'DASHBOARD_REBATE'} changeTab={() => changeTab("history/rebate")} keys={"5"} />
            }
            {/* <div className='m-dashboard-wallets-switch'>
                <Switch defaultChecked />
                <div className='m-dashboard-wallets-switch-text'><FormattedMessage id={'DASHBOARD_WALLET_AUTO_TRANSFER'} /></div>
            </div> */}
        </div>
    )
}

export default DashboardMobileTopButtons;