

import "../HomeMobile.less";
import { FormattedMessage } from "react-intl";
import { useAuth } from "../../../../hooks/auth/AuthContext";
import { checkIsGameAvailable, checkIsGameComingSoon, checkIsGameUnderMaintenance } from "../../../../shared/helpers/general-helpers";
interface Props {
    game: any;
    onGameClick: any;
}

const MobileGameListIcons = ({ game, onGameClick }: Props) => {
    const { authState } = useAuth();

    return (
        <div
            className={`mobile-game-list-icons ${game.mobileBgClass} ${checkIsGameAvailable(game, authState?.maintenanceList ?? []) ? '' : 'inactive'}`}
            onClick={checkIsGameAvailable(game, authState?.maintenanceList ?? []) ? () => { onGameClick(game) } : () => { }}>
            <div className={
                checkIsGameAvailable(game, authState?.maintenanceList ?? [])
                    ? "coming-soon-txt-inactive" :
                    checkIsGameComingSoon(game) ? "coming-soon-txt" :
                        "maintenance-txt"} >
                <FormattedMessage id={checkIsGameUnderMaintenance(game, authState?.maintenanceList ?? []) ? 'MAINTENANCE' : 'COMING_SOON'} />
            </div>
        </div>
    );
};

export default MobileGameListIcons;
