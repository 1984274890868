
import "../Auth.less";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { useEffect } from "react";
import { formatUrlCurrencyLang } from "../../../shared/helpers/general-helpers";

interface Props {
    backToFirstPage: () => void;
}

const RegisterStep3 = ({ backToFirstPage }: Props) => {
    const navigate = useNavigate();
    const { authState } = useAuth();

    const goDepositPage = () => {
        navigate(`/${formatUrlCurrencyLang()}/dashboard`);
    }

    return (
        <>
            <div className="register-form-title"><FormattedMessage id={authState.isLogin ? 'AUTH_REGISTER_STEP3' : 'AUTH_REGISTER_STEP3_FAILED'} /></div>
            <div className="register-form-item">
                <FormattedMessage id={authState.isLogin ? 'AUTH_REGISTER_SUCCESS' : 'AUTH_REGISTER_FAILED'} />
            </div>
            <Button block type="primary" htmlType="submit" className="register-deposit-btn" onClick={authState.isLogin ? goDepositPage : backToFirstPage} >
                <FormattedMessage id={authState.isLogin ? "AUTH_REGISTER_BTN_DEPOSIT" : 'AUTH_REGISTER_BACK_FIRST'} />
            </Button>
        </>
    );
};

export default RegisterStep3;
