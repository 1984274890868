
import { Layout } from 'antd';
import FooterContent from './FooterElements/FooterContent';
import FooterFirstRowLeft from './FooterElements/FooterFirstRowLeft';
import './GeneralFooter.less';
import FooterPaymentMethod from './FooterElements/FooterPaymentMethod';
import FooterCertification from './FooterElements/FooterCertification';
import FooterCopyRight from './FooterElements/FooterCopyRight';
import FooterSocialMedia from './FooterElements/FooterSocialMedia';
import FooterProviders from './FooterElements/FooterProviders';
import FooterResponsibleGaming from './FooterElements/FooterResponsibleGaming';
import FooterSecurity from './FooterElements/FooterSecurity';
import { useAuth } from '../../../hooks/auth/AuthContext';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { gamesProviderLeft, gamesProviderRight } from '../top-menu/TopMenuList';
import FooterPartnership from './FooterElements/FooterPartnership';
import { formatUrlCurrencyLang } from '../../helpers/general-helpers';

const { Footer } = Layout;

const GeneralFooter = () => {
    const { authState } = useAuth();
    const navigate = useNavigate();

    const onMenuItemClick = (provider: string) => {
        navigate(provider);
    }

    const onProductClick = (gameType: string, game: any) => {
        //if (authState.isLogin)
        if (gameType === 'slots' || gameType === 'fishing') {
            onMenuItemClick(game.url);
        } else {
            navigate(`landing/${gameType}`);
        }
        // else
        //     setLoginModalVisible(true);
    }


    const gameMenu = (gameList: any[]) => {
        return gameList.map(gp => (
            ((authState.isLogin && gp.currency.filter((x: any) => x === authState.userProfile?.currency).length > 0) ||
                (!authState.isLogin && gp.currency.filter((x: any) => x === authState?.defaultCurrency).length > 0)) &&
            <li key={gp.gameType} className="menu-item">
                <div key={gp.gameType + '_label'} className="menu-item-component" onClick={() => { onProductClick(gp.gameType, gp.games[0]) }}>
                    <div className="menu-item-label"><FormattedMessage id={gp.label} /></div>
                    {/* <img className="menu-chevron-down" src={ChevronDown} alt="home" /> */}
                </div>
            </li>
        ))
    }

    return (
        <Footer className="site-layout-footer">
            <div className='footer-first-row'>
                <FooterFirstRowLeft />
            </div>
            {/* <FooterContent></FooterContent> */}
            {/* <FooterSocialMedia /> */}
            {/* <FooterProviders /> */}
            <div className='footer-elements-container'>
                <div className='footer-element-items'>
                    <FooterCopyRight />
                </div>
                <div className='footer-element-items'>
                    <FooterCertification />
                    <FooterPaymentMethod />
                </div>
                <div className='footer-element-items'>
                    <FooterSecurity />
                    <FooterResponsibleGaming />
                    <FooterPartnership />
                </div>
            </div>
            <div className='footer-sitemap'>
                <ul className='game-list'>
                    {gamesProviderLeft && gamesProviderLeft.length > 0 && gameMenu(gamesProviderLeft)}
                    {gamesProviderRight && gamesProviderRight.length > 0 && gameMenu(gamesProviderRight)}
                </ul>
                <FooterSocialMedia />
            </div>
        </Footer>
    )
}

export default GeneralFooter;