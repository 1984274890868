import { FormattedMessage } from 'react-intl';
import '../GeneralFooter.less';

import Cert_1 from '../../../../assets/images/icon-certification-01.svg';
import Cert_2 from '../../../../assets/images/icon-certification-02.svg';
import Cert_3 from '../../../../assets/images/icon-certification-03.svg';
import Cert_4 from '../../../../assets/images/icon-certification-04.svg';
import Cert_5 from '../../../../assets/images/icon-certification-05.svg';

import Res_1 from '../../../../assets/images/icon-responsible-01.svg';
import Res_2 from '../../../../assets/images/icon-responsible-02.svg';
import Res_3 from '../../../../assets/images/icon-responsible-03.svg';

const FooterResponsibleGaming = () => {
    return (
        <div className='footer-third-row'>
            <div className='footer-third-row-item-right'>
                <div className='footer-third-row-item-title'>
                    <FormattedMessage id='FOOTER_RESPONSIBLE' />
                </div>
                <div className='footer-third-row-icons'>
                    <div className='footer-provider-items'>
                        <img src={Res_1} alt="responsible 18 above" className="provider-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Res_2} alt="responsible gamcare" className="provider-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Res_3} alt="responsible be gamble aware" className="provider-icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterResponsibleGaming;