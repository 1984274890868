
import '../../DashboardMobile.less';
import '../DashboardMobileWallets.less';

import iconRefresh from '../../../../../assets/images/icon-rotate-orange.svg';
import iconWallet from '../../../../../assets/images/icon-banking-deposit-white.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SET_USER_WALLET, useAuth } from '../../../../../hooks/auth/AuthContext';
import { GET_WALLET_BALANCE, TRANSFER_TO_WALLET } from '../../../../dashboard/dashboard-wallets/DashboardWallets.gql';
import { GET_WALLETS } from '../../../../dashboard/dashboard-bottom/components/transfer/DashboardTransfer.gql';
import { forEach } from 'lodash';
import useSpinner from '../../../../../hooks/layout/useSpinner';
import useNotification from '../../../../../hooks/layout/useNotification';
import userIcon from '../../../../../assets/images/icon-profile-orange.svg';
import { useNavigate } from 'react-router-dom';
import { formatUrlCurrencyLang } from '../../../../../shared/helpers/general-helpers';

const DashboardMobileTopWallet = () => {
    const navigate = useNavigate();
    const { setLoading } = useSpinner();
    const intl = useIntl();
    const { authState, authDispatch } = useAuth();
    const [balance, setUserBalance] = useState<number>(0);
    const [isFlash, setIsFlash] = useState<boolean>(false);
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [getWalletBalance, { data, loading }] = useLazyQuery(GET_WALLET_BALANCE);
    const [getWallets, { data: walletData, loading: walletLoading }] = useLazyQuery(GET_WALLETS,
        { fetchPolicy: "cache-and-network" });
    const [transferToWallet, { data: transferData, loading: transferLoading }] =
        useMutation(TRANSFER_TO_WALLET, { fetchPolicy: "no-cache", errorPolicy: "all" });

    useEffect(() => {
        getWalletBalance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (transferLoading || walletLoading)
            setLoading(true);
        else
            setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferLoading, walletLoading])

    useEffect(() => {
        if (data && data.walletBalance) {
            setUserBalance(+data.walletBalance?.totalBalance.toFixed(2));
        }
    }, [data]);

    useEffect(() => {
        if (authState && authState?.wallets && authState?.wallets.length > 0
            && authState?.wallets.filter((x: { userWalletType: any; walletType: string; }) => x.userWalletType.id === 'MAIN').length > 0) {
            setUserBalance(+authState?.wallets.filter((x: { userWalletType: any; walletType: string; }) => x.userWalletType.id === 'MAIN')[0].balance.toFixed(2));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState?.wallets]);

    useEffect(() => {
        if (walletData && walletData.wallets && walletData.wallets.wallets) {
            let walletList: any = [];
            let totalWalletBalance = 0;

            forEach(walletData.wallets.wallets, (wallet) => {
                totalWalletBalance += +wallet?.balance;
                walletList.push({
                    name: wallet.walletType,
                    value: wallet.id,
                    balance: wallet.balance,
                    userWalletType: wallet.userWalletType,
                    isMaintenance: wallet.isMaintenance
                });
            });

            let categoryList = walletData.wallets.walletCategory;

            let bonusWalletType: any = [];

            if (walletData && walletData.wallets && walletData.wallets.userBonusTracker) {
                bonusWalletType = walletData.wallets.userBonusTracker.userDepositBonus?.bonusSetting?.BonusSettingWalletType;
            }

            authDispatch({ type: SET_USER_WALLET, payload: { wallets: walletList, walletCategory: categoryList, totalBalance: +totalWalletBalance, bonusWalletType: bonusWalletType } });
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletData])

    useEffect(() => {
        if (transferData && transferData.transferToWallet) {
            if (transferData.transferToWallet?.status === 'SUCCESS') {
                setSuccessNotification(intl.formatMessage({ id: 'DASHBOARD_TRANSFER_SUCCESS' }));
                setUserBalance(transferData.transferToWallet?.totalBalance);
            } else if (transferData.transferToWallet.status === 'PARTAILLY_SUCCESS') {
                setSuccessNotification(intl.formatMessage({ id: `ERROR_${transferData.transferToWallet.status}` }));
            } else {
                setErrorNotification(intl.formatMessage({ id: `ERROR_${transferData.transferToWallet.status}` }));
            }
            getWallets();
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferData]);

    useEffect(() => {
        if (loading || transferLoading || walletLoading) {
            setIsFlash(true);
        } else {
            setTimeout(() => {
                setIsFlash(false);
            }, 600);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, transferLoading, walletLoading]);

    const refreshBalance = () => {
        getWalletBalance();
    }

    const onTransferToWallet = () => {
        transferToWallet();
    }

    const onUserClick = () => {
        navigate(`dashboard/profile/my-profile`);
    }

    return (
        <div className='m-dashboard-top-wallet-container'>
            <div className="m-dashboard-wallets-wallet-container">
                {authState?.userProfile?.currency &&
                    <div className='m-currency-refresh'>
                        <div className='m-dashboard-wallets-wallet-currency'><FormattedMessage id={'DASHBOARD_TRANSFER_DROPDOWN_PLACEHOLDER'} /></div>
                    </div>
                }
                <div className='m-dashboard-wallets-wallet-balance'>
                    <div className='wallet-currency'><FormattedMessage id={`CURRENCY_${authState?.userProfile?.currency}`} /></div>
                    <div className='wallet-amt'><NumberFormat displayType="text" value={balance.toFixed(2)} thousandSeparator={true} className={isFlash ? 'flash' : ''} /></div>
                    <img src={iconRefresh} alt="refresh balance" className="m-wallet-refresh-icon" onClick={refreshBalance} />
                </div>
                <div className='m-dashboard-wallets-wallet-restore' onClick={onTransferToWallet}>
                    <img src={iconWallet} alt="wallet balance" className='m-wallet-bal-icon' />
                    <div className='m-dashboard-wallets-wallet-restore-txt'>
                        <FormattedMessage id={'DASHBOARD_RESTORE_WALLET'} />
                    </div>
                </div>
            </div>
            <div className='top-wallet-icon-container'>
                <img className='top-wallet-user-icon' src={userIcon} alt='user' onClick={onUserClick} />
            </div>
        </div>
    )
}

export default DashboardMobileTopWallet;
