import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo-client';
import { AuthProvider } from './hooks/auth/AuthContext';
import { LayoutProvider } from './hooks/layout/LayoutContext';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <CookiesProvider>
      <LayoutProvider>
        <ApolloProvider client={client}>
          <AuthProvider>
            <Router>
              <App />
            </Router>
          </AuthProvider>
        </ApolloProvider>
      </LayoutProvider>
    </CookiesProvider>,
    document.getElementById('root')
  );

  let iOSUserAgentPattern = /iP(hone|od|ad)/;
  let linePattern = /Line\//i;

  if (!(iOSUserAgentPattern.test(navigator.userAgent) && linePattern.test(navigator.userAgent))) {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register();
  }

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});

