import '../GeneralHeader.less';
import { useEffect, useState } from 'react';
import Logo from '../../../../assets/images/logo.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const HeaderLeft = () => {
    const intl = useIntl();
    const [liveDate, setLiveDate] = useState<string>();
    const navigate = useNavigate();

    var time = new Date();

    useEffect(() => {
        setInterval(intervalFunc, 1000);
    }, [])

    const intervalFunc = () => {
        time.setTime(time.getTime() + 1000);

        var dd = time.getDate();
        var MM = (time.getMonth() + 1);
        var yyyy = time.getFullYear();
        var HH = time.getHours();


        var mm = time.getMinutes();
        var ss = time.getSeconds();

        var s_now = dd + '/' + MM + '/' + yyyy + " " + HH + ":" + (mm < 10 ? "0" + mm : mm) + ":" + (ss < 10 ? "0" + ss : ss);
        setLiveDate(s_now);
    }

    return (
        <div className='header-left-elements'>
            {/* <div className="header-datetime">{liveDate} (GMT+8)</div> */}
            <img className='header-logo' src={Logo} alt={intl.formatMessage({ id: "SEO_LOGO" })} onClick={() => { navigate('/') }} />
        </div>
    )
}

export default HeaderLeft;