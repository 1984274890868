import { gql } from "@apollo/client";

export const GET_WALLET_BALANCE = gql`
    query WalletBalance{
        walletBalance{
            totalBalance
        }
    }
`;

export const TRANSFER_TO_WALLET = gql`
    mutation TransferToWallet{
        transferToWallet{
            status,
            totalBalance
        }
    }
`;