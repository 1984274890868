import React from "react";
import NoMatch from "./NoMatch";

export interface MobileRouteInfo {
	path: string;
	component: any;
	routes?: MobileRouteInfo[];
	lazy?: boolean;
	protected?: boolean;
}

const ReactLazyPreload = (importStatement: any) => {
	const Component: any = React.lazy(importStatement);
	Component.preload = importStatement;
	return Component;
};

const Register = ReactLazyPreload(() => import("./pages/_mobile/auth/RegisterMobile"));
Register.preload();

const AboutUsMobile = ReactLazyPreload(() => import("./pages/_mobile/menu-drawer-pages/AboutUs"));
AboutUsMobile.preload();

const TNCMobile = ReactLazyPreload(() => import("./pages/_mobile/menu-drawer-pages/TermsCondition"));
TNCMobile.preload();

const ContactUs = ReactLazyPreload(() => import("./pages/footer-menu-pages/ContactUs"));
ContactUs.preload();

// const Dashboard = ReactLazyPreload(() => import("./pages/_mobile/dashboard/DashboardMobile"));
// Dashboard.preload();

const Promo = ReactLazyPreload(() => import("./pages/_mobile/promo/PromoMobile"));
Promo.preload();

const SlotGamePage = ReactLazyPreload(() => import("./pages/_mobile/provider-game-page/slots/SlotGamesPageMobile"));
SlotGamePage.preload();

const FishGamePage = ReactLazyPreload(() => import("./pages/_mobile/provider-game-page/fish/FishGamePageMobile"));
FishGamePage.preload();

const FAQPage = ReactLazyPreload(() => import("./pages/_mobile/menu-drawer-pages/FAQ"));
FAQPage.preload();

const DashboardProfile = ReactLazyPreload(() => import("./pages/_mobile/dashboard_v2/dashboard-profile/DashboardProfile"));
DashboardProfile.preload();

const DashboardBanking = ReactLazyPreload(() => import("./pages/_mobile/dashboard_v2/dashboard-banking/DashboardBanking"));
DashboardBanking.preload();

const DashboardDeposit = ReactLazyPreload(() => import("./pages/_mobile/dashboard_v2/components/deposit/DashboardMobileDeposit"));
DashboardDeposit.preload();

const DashboardTransfer = ReactLazyPreload(() => import("./pages/_mobile/dashboard_v2/components/transfer/DashboardMobileTransfer"));
DashboardTransfer.preload();

const DashboardWithdraw = ReactLazyPreload(() => import("./pages/_mobile/dashboard_v2/components/withdrawal-detail/DashboardMobileWithdrawDetail"));
DashboardWithdraw.preload();

const DashboardHistory = ReactLazyPreload(() => import("./pages/_mobile/dashboard_v2/components/history/DashboardMobileHistory"));
DashboardHistory.preload();

const DashboardProfileV2 = ReactLazyPreload(() => import("./pages/_mobile/dashboard_v2/components/profile/DashboardMobileProfile"));
DashboardProfileV2.preload();

const DashboardChangePassword = ReactLazyPreload(() => import("./pages/_mobile/dashboard_v2/components/change-password/DashboardMobileChangePassword"));
DashboardChangePassword.preload();

const DashboardMessaging = ReactLazyPreload(() => import("./pages/_mobile/dashboard_v2/components/messages/DashboardMobileMessages"));
DashboardMessaging.preload();

const DashboardReferral = ReactLazyPreload(() => import("./pages/_mobile/dashboard_v2/components/referral/DashboardMobileReferral"));
DashboardReferral.preload();

const LiveScore = ReactLazyPreload(()=> import("./pages/live-score/LiveScore"));
LiveScore.preload();

const InstantWinGamePage = ReactLazyPreload(() => import("./pages/_mobile/provider-game-page/instant-win/InstantWinGamesPageMobile"));
InstantWinGamePage.preload();

export const routes: MobileRouteInfo[] = [
	{
		path: "/register",
		component: Register,
		protected: true,
	},
	{
		path: "/about-us",
		component: AboutUsMobile,
		protected: true,
	},
	{
		path: "/terms-and-conditions",
		component: TNCMobile,
		protected: true,
	},
	{
		path: "/contact-us",
		component: ContactUs,
		protected: true,
	},
	// {
	// 	path: "/dashboard/:id",
	// 	component: Dashboard,
	// 	protected: true,
	// },
	{
		path: "/promotions",
		component: Promo,
		protected: true,
	},
	{
		path: "/slots/:id",
		component: SlotGamePage,
		protected: true,
	},
	{
		path: "/fishing/:id",
		component: FishGamePage,
		protected: true,
	},
	{
		path: "/faq",
		component: FAQPage,
		protected: true,
	},
	{
		path: "/dashboard/banking/deposit",
		component: DashboardDeposit,
		protected: true,
	},
	{
		path: "/dashboard/banking/transfer",
		component: DashboardTransfer,
		protected: true,
	},
	{
		path: "/dashboard/banking/withdrawal",
		component: DashboardWithdraw,
		protected: true,
	},
	{
		path: "/dashboard/banking/history/:id",
		component: DashboardHistory,
		protected: true,
	},
	{
		path: "/dashboard/banking/history",
		component: DashboardHistory,
		protected: true,
	},
	{
		path: "/dashboard/banking",
		component: DashboardBanking,
		protected: true,
	},
	{
		path: "/dashboard/profile/my-profile",
		component: DashboardProfileV2,
		protected: true,
	},
	{
		path: "/dashboard/profile/change-password",
		component: DashboardChangePassword,
		protected: true,
	},
	{
		path: "/dashboard/profile/messaging",
		component: DashboardMessaging,
		protected: true,
	},
	{
		path: "/dashboard/profile/referral",
		component: DashboardReferral,
		protected: true,
	},
	{
		path: "/dashboard/profile",
		component: DashboardProfile,
		protected: true,
	},
	{
		path: "/live-score",
		component: LiveScore,
		protected: true,
	},
	{
		path: "/instant-win/:id",
		component: InstantWinGamePage,
		protected: true,
	},
	// {
	// 	path: "/dashboard",
	// 	component: Dashboard,
	// 	protected: true,
	// },
	{
		path: "*",
		component: NoMatch,
	},
];