import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { gamesProviderLeft } from '../../../shared/components/top-menu/TopMenuList';
import { useAuth } from '../../../hooks/auth/AuthContext';
import LoginModal from '../../../shared/login_modal/LoginModal';
import { useNavigate } from 'react-router-dom';
import useSpinner from '../../../hooks/layout/useSpinner';
import { useMutation } from '@apollo/client';
import { GET_GAME_URL } from '../../provider-game-page/Game.gql';

import ribbon1 from '../../../assets/images/flying-ribbon-1.png';
import ribbon2 from '../../../assets/images/flying-ribbon-2.png';
import { forEach } from 'lodash';

const HomeSportPlay = () => {
    const navigate = useNavigate();
    const { setLoading } = useSpinner();
    const { authState } = useAuth();
    const intl = useIntl();
    const [loginModalVIsible, setLoginModalVisible] = useState(false);
    const [getGameUrl, { data: gameData, loading: gameLoading, error: gameError }] = useMutation(GET_GAME_URL,
        { fetchPolicy: "no-cache" });
    const [selectedSportGame, setSelectedSportGame] = useState('');
    const [selectedGame, setSelectedGame] = useState<any>();
    const [sportList, setSportList] = useState<any>([]);

    useEffect(() => {
        let _sportsList: any = [];
        let sportProviders = gamesProviderLeft.filter(x => x.gameType === 'sports')[0].games;
        let count = 0;

        forEach(sportProviders, (game: any) => {
            if ((authState?.isLogin && game?.currency.filter((x: any) => x === authState.userProfile?.currency).length > 0) ||
                (game?.currency.filter((x: any) => x === authState?.defaultCurrency).length > 0)) {
                _sportsList.push(game);

                if (count === 0) {
                    setSelectedSportGame(game?.id);
                }

                count++;
            }
        });

        setSportList(_sportsList);
    }, [authState?.defaultCurrency])

    useEffect(() => {
        setLoading(gameLoading); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameLoading])

    useEffect(() => {
        if (gameData && gameData.gameUrl && gameData.gameUrl.url) {
            // if (popupWindow) {
            //     popupWindow.location.href = gameData.gameUrl.url;
            window.open(gameData.gameUrl.url, "gameTab");
            //}

            // setSelectedGameUrl(gameData.gameUrl.url);
            //setTransferModalVisible(true);
        } else {
            // if (popupWindow) {
            //     popupWindow.close();
            // }
        }
    }, [gameData]);

    const onGameClick = (game: any) => {
        //setSelectedGame(game);
        if (authState.isLogin) {
            setSelectedGame(game);
            game.hasUrl ? onMenuRedirectClick(game.url, game.gameCode) : onMenuItemClick(game.url);
            //game.hasUrl ? setTransferModalVisible(true) : onMenuItemClick(game.url);
        } else
            setLoginModalVisible(true);
    }

    const onMenuItemClick = (provider: string) => {
        navigate(provider);
    }

    const onMenuRedirectClick = (providerCode: string, gameCode: string) => {
        // const promptWindow = window.open(gameCode, "_blank");
        // if (promptWindow)
        //     setPopupWindow(promptWindow);

        getGameUrl({
            variables: {
                gameUrlInput: {
                    gameProviderCode: providerCode,
                    platform: 'web',
                    lang: intl.locale,
                    isFreeGame: 'false',
                    gameCode
                }
            }
        });
    }

    return (
        <>
            <div className='sports-provider-container'>
                <div className='sports-title'><FormattedMessage id='BEST_SPORTS_GAMES' /></div>
                <div className='sports-select-container'>
                    <div className='sports-thumbs-container'>
                        {sportList.map((game: any) => {
                            if ((authState?.isLogin && game?.currency.filter((x: any) => x === authState.userProfile?.currency).length > 0) ||
                                (!authState.isLogin && game?.currency.filter((x: any) => x === authState?.defaultCurrency).length > 0)) {
                                return (<div key={game?.id} className={game?.id === selectedSportGame ? 'selected-sports-thumb' : 'sports-thumb'}
                                    onClick={() => { setSelectedSportGame(game?.id) }}>
                                    <img src={game?.thumbnail} alt={game?.id} />
                                </div>)
                            }
                        })}
                    </div>
                    <div className='sports-play-container'>
                        {sportList.map((game: any, index: number) => {
                            if ((authState?.isLogin && game?.currency.filter((x: any) => x === authState.userProfile?.currency).length > 0) ||
                                (!authState.isLogin && game?.currency.filter((x: any) => x === authState?.defaultCurrency).length > 0)) {
                                if (game?.id === selectedSportGame)
                                    return (<div key={game?.id} className='sports-play'>
                                        <img src={game?.sportsSectionImg} alt={game?.id} />
                                        <img className={`ribbon-1-${index}`} src={ribbon1} alt={game?.id} />
                                        <img className={`ribbon-2-${index}`} src={ribbon2} alt={game?.id} />
                                        <div className="game-list-bet-btn" onClick={() => { onGameClick(game) }}>
                                            <FormattedMessage id='M_HOME_GAME_CARD_BET_BUTTON' />
                                        </div>
                                    </div>)
                            }
                        })}
                    </div>
                </div>
            </div>
            <LoginModal modalVisible={{ visible: loginModalVIsible, setVisible: setLoginModalVisible }} />
        </>
    )
}

export default HomeSportPlay;