import { FormattedMessage } from 'react-intl';
import HomeUpcomingMatches from './Home-Upcoming-Matches';
import HomeSportPlay from './Home-Sport-Play';

const HomeSportsContainer = () => {
    return (
        <>
            <div className="home-sports-container">
                <div className='home-sports-elements'>
                    <HomeSportPlay/>
                    <div className='upcoming-match-container'>
                        <div className='sports-title'><FormattedMessage id='UPCOMING_EVENTS' /></div>
                        <HomeUpcomingMatches showBtn={false} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeSportsContainer;