


import { useIntl } from 'react-intl';
import { Form, Input, Modal } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import './LoginModal.less';
import { encode } from 'base-64';
import PrimaryButton from '../components/Button/PrimaryButton';
import { useMutation } from '@apollo/client';
import { SIGN_IN } from '../../pages/auth/auth.gql';
import { setTokenCookie } from '../helpers/set-token-cookie.helper';
import { useNavigate } from 'react-router-dom';
import { SET_LOGIN_STATUS, useAuth } from '../../hooks/auth/AuthContext';
import useSpinner from '../../hooks/layout/useSpinner';
import PopUpModal from '../components/modals/PopUpModal';

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
}

const LoginModal = ({ modalVisible }: Props) => {
    const intl = useIntl();
    const { setLoading } = useSpinner();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { authDispatch } = useAuth();
    const [signIn, { data, loading }] = useMutation(SIGN_IN, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    useEffect(() => {
        setLoading(loading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    const onFinish = (fieldsValue: any) => {
        signIn({
            variables: {
                userName: fieldsValue.login_username,
                password: encode(fieldsValue.login_password)
            },
        });
    }

    useEffect(() => {
        if (data && data.signIn) {
            modalVisible.setVisible(false);
            const { a, r, sid } = data.signIn;
            setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || "gmf-at", a);
            setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || "gmf-rt", r);
            setTokenCookie("gmf-sid", sid);
            authDispatch({ type: SET_LOGIN_STATUS, payload: true });
            navigate('dashboard');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <PopUpModal modalVisible={modalVisible} component={
                <>
                    <Form
                        onFinish={onFinish}
                        form={form}>
                        <div className='login-form-content-container'>
                            <Form.Item
                                name='login_username'
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                                    {
                                        pattern: /^[A-Za-z][A-Za-z0-9]{3,14}$/,
                                        message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME_INVALID' })
                                    }
                                ]}
                            >
                                <Input placeholder={intl.formatMessage({ id: 'LOGIN_USERNAME_PLACEHOLDER' })} />
                            </Form.Item>
                        </div>
                        <div className='login-form-content-container'>
                            <Form.Item
                                name='login_password'
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: 'PASSWORD_REQUIRED' }) },
                                    {
                                        pattern: /[A-Za-z0-9]{6,19}$/,
                                        message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_INVALID' })
                                    }
                                ]}
                            >
                                <Input.Password placeholder={intl.formatMessage({ id: 'LOGIN_PASSWORD_PLACEHOLDER' })} />
                            </Form.Item>
                        </div>
                        <PrimaryButton loading={loading} additionalClassName='primary-btn' btnText={'LOGIN_LOGIN_BTN'} isFormBtn={true} onClick={() => { }} />
                    </Form>
                </>
            } />
        </>
    )
}

export default LoginModal;