
import "./AuthMobile.less";
import { Steps, Row, Col } from "antd";
import { useEffect, useState } from 'react';
import RegisterMobileStep1 from "./register-components/RegisterStep1";

import bannerImg from '../../../assets/images/register-banner.jpg';
import bannerImgTh from '../../../assets/images/register-banner-th.jpg';
import RegisterMobileStep2 from "./register-components/RegisterStep2";
import RegisterMobileStep3 from "./register-components/RegisterStep3";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import RegisterBanners from "../../auth/register-components/RegisterBanners";
import registerBottomBanner from '../../../assets/images/register-steps-sg.jpg';
import registerBottomBannerTh from '../../../assets/images/register-steps-th.jpg';
import { GET_CONFIGURATIONS } from "../../auth/auth.gql";
import { useLazyQuery } from "@apollo/client";
import _ from "lodash";

const { Step } = Steps;

const RegisterMobile = () => {
    const { innerWidth: width } = window;
    const { authState } = useAuth();
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();
    const { layoutState } = useLayout();
    const [getBottomBanner, { data: getData }] = useLazyQuery(GET_CONFIGURATIONS, { fetchPolicy: "cache-and-network" });
    const [bottomBanner, setBottomBanner] = useState<any>();

    useEffect(() => {
        if (layoutState.locale && authState?.defaultCurrency) {
            const localFromStorage = localStorage.getItem('pfxl') ?? 'en';

            getBottomBanner({
                variables: {
                    config: `registerSideBanner${_.capitalize(authState?.defaultCurrency?.toLowerCase())}${localFromStorage}`,
                }
            })
        }
    }, [layoutState.locale, authState?.defaultCurrency])

    useEffect(()=>{
        if(getData && getData?.configurations) {
            setBottomBanner(getData?.configurations?.value)
        }
    },[getData])

    useEffect(() => {
        if (authState && authState.isLogin) {
            navigate(`dashboard`);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const next = () => {
        setCurrentStep(currentStep + 1);
    };

    const backToFirstPage = () => {
        setCurrentStep(0);
    }

    const steps = [
        // {
        //     id: '1',
        //     content: <RegisterMobileStep1 next={next} step={currentStep + 1} />
        // },
        {
            id: '2',
            content: <RegisterMobileStep2 next={next} step={currentStep + 1} />
        },
        {
            id: '3',
            content: <RegisterMobileStep3 backToFirstPage={backToFirstPage} />
        },
    ];

    return (
        <div className="m-register-container">
            <div className="m-register-wrapper">

                <div className="m-register-form-container">
                    <div className="m-register-top-container">
                        <div className="m-register-form-top-element">
                            {/* <img src={layoutState.locale === 'th' ? bannerImgTh : bannerImg} alt="banner" className="m-reg-img" /> */}
                            <RegisterBanners />
                        </div>
                        <div className="m-register-form-bottom-element">
                            <Steps
                                direction={"horizontal"}
                                current={currentStep}
                                size="default"
                            >
                                {steps.map((step) => (
                                    <Step key={step.id} />
                                ))}
                            </Steps>
                            <div className="m-register-form-items">{steps[currentStep].content}</div>
                        </div>
                    </div>
                    <div className="bottom-banner">
                        {/* {authState?.defaultCurrency === 'THB' ? */}
                        <img src={bottomBanner ?? registerBottomBanner} alt='bottom-banner' />
                        {/* <img src={registerBottomBanner} alt='bottom-banner' />
                        } */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterMobile;
