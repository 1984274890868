import { FormattedMessage } from 'react-intl';
import '../GeneralFooter.less';

import Cert_1 from '../../../../assets/images/icon-certification-01.svg';
import Cert_2 from '../../../../assets/images/icon-certification-02.svg';
import Cert_3 from '../../../../assets/images/icon-certification-03.svg';
import Cert_4 from '../../../../assets/images/icon-certification-04.svg';
import Cert_5 from '../../../../assets/images/icon-certification-05.svg';

import Res_1 from '../../../../assets/images/icon-responsible-01.svg';
import Res_2 from '../../../../assets/images/icon-responsible-02.svg';
import Res_3 from '../../../../assets/images/icon-responsible-03.svg';

const FooterCertification = () => {
    return (
        <div className='footer-third-row'>
            <div className='footer-third-row-item-left'>
                <div className='footer-third-row-item-title'>
                    <FormattedMessage id='FOOTER_CERTIFICATION' />
                </div>
                <div className='footer-third-row-icons'>
                    <div className='footer-provider-items'>
                        <img src={Cert_1} alt="certification bmm" className="certification-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Cert_2} alt="certification itech lab" className="certification-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Cert_3} alt="certification gaming laboratories international" className="certification-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Cert_4} alt="certification tt" className="certification-icon" />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={Cert_5} alt="certification godaddy" className="certification-icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterCertification;