import DashboardTopInnerMenu from "../dashboard/dashboard-bottom/components/shared/dashboard-top-inner-menu/DashboardTopInnerMenu";

import PromoCard from "./components/PromoAll";
import { GET_PROMOTIONS } from "./Promo.gql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import useSpinner from "../../hooks/layout/useSpinner";
import moment from "moment";
import { forEach } from "lodash";
import { useLayout } from "../../hooks/layout/LayoutContext";
import { setDBLanguageToGet } from "../../shared/helpers/language.helpers";
import { setTokenCookie } from "../../shared/helpers/set-token-cookie.helper";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/auth/AuthContext";
import { useIntl } from "react-intl";
import { formatSEOCountry } from "../../shared/helpers/general-helpers";
import { Helmet } from "react-helmet";

const Promo = () => {
    const { authState } = useAuth();
    const { layoutState } = useLayout();
    const { setLoading } = useSpinner();
    // const { data, loading } = useQuery(GET_PROMOTIONS, { variables: { currency: authState?.defaultCurrency }, fetchPolicy: 'cache-and-network' });
    const [getPromotions, { data, loading }] = useLazyQuery(GET_PROMOTIONS, { fetchPolicy: 'cache-and-network' });
    //const { data: pcdData, loading: pcdLoading } = useQuery(GET_PROMOTION_CATEGORIES, { fetchPolicy: 'cache-and-network' });
    const [promotionList, setPromotionList] = useState<any>();
    const [promotionCategories, setPromotionCategories] = useState<any>();
    const [promoTabList, setPromoTabList] = useState<any>();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const intl = useIntl();
    const [title, setTitle] = useState(intl.formatMessage({ id: 'SEO_TITLE_PROMO' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }));
    const [desc, setDesc] = useState(intl.formatMessage({ id: 'SEO_DESC_PROMO' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }));

    useEffect(() => {
        setTitle(intl.formatMessage({ id: 'SEO_TITLE_PROMO' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }));
        setDesc(intl.formatMessage({ id: 'SEO_DESC_PROMO' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }))
    }, [localStorage.getItem('pfxl')])

    useEffect(() => {
        if (query.get('refId')) {
            const paramRefId = query.get('refId') ?? '';
            setTokenCookie("cookieRefId", paramRefId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (authState?.defaultCurrency) {
            getPromotions({
                variables: { currency: authState?.defaultCurrency }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState?.defaultCurrency]);

    useEffect(() => {
        setLoading(loading); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    useEffect(() => {
        if (promotionList && promotionCategories)
            buildPromoTabList(promotionCategories, promotionList)
    }, [layoutState.locale])

    useEffect(() => {
        if (data) {
            const _datas = data.promotions && data.promotions.promotions && data.promotions.promotions.length > 0 ?
                data.promotions.promotions.map((val: any) => {
                    let dayDiff = 0;
                    let hourDiff = 0;

                    if (val.displayTo) {
                        let current = moment();
                        let endDate = moment(val.displayTo);

                        dayDiff = (Math.floor(endDate.diff(current, 'hours') / 24));
                        hourDiff = (endDate.diff(current, 'hours') % 24);
                    }

                    return {
                        ...val,
                        remainingDay: dayDiff,
                        remainingHour: hourDiff,
                        key: val.id
                    };
                }) : [];
            setPromotionList(_datas);
            const _pcddatas = data.promotions && data.promotions.promotionCategories && data.promotions.promotionCategories.length > 0 ?
                data.promotions.promotionCategories.map((val: any) => {
                    return {
                        ...val,
                        key: val.id
                    };
                }) : [];

            setPromotionCategories(_pcddatas);
            buildPromoTabList(_pcddatas, _datas);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const buildPromoTabList = (pcddatas: any, pdatas: any) => {
        if (pcddatas && pcddatas.length > 0) {
            let localTabList: any = [];

            forEach(pcddatas, (category) => {
                localTabList.push({
                    tabName: category.name ? JSON.parse(category?.name)[setDBLanguageToGet(layoutState.locale)] : '-',
                    count: category.id === 'cl5eyta2j000009mghy2627t9' ?
                        pdatas.length
                        :
                        (pdatas?.filter((x: { category: string[]; }) => x.category && x.category.includes(category.id)).length),
                    key: category?.id,
                    element: category.id === 'cl5eyta2j000009mghy2627t9' ?
                        <PromoCard promoList={pdatas} /> :
                        <PromoCard promoList={pdatas?.filter((x: { category: string[]; }) => x.category && x.category.includes(category.id))} />
                })
            });

            setPromoTabList(localTabList);
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={desc} />
            </Helmet>
            <div className="promo-container">
                {promoTabList && promoTabList.length > 0 &&
                    <DashboardTopInnerMenu needTranslation={false} elementList={promoTabList} />
                }
            </div>
        </>
    )
}

export default Promo;