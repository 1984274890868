import { useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { setDBLanguageToGet } from "../../../shared/helpers/language.helpers";
import { useNavigate } from 'react-router-dom';
import '../Promo.less';
import { Modal } from "antd";
import HeaderLoginMobile from "../../../shared/_mobile/header/HeaderLoginMobile";
import useSpinner from "../../../hooks/layout/useSpinner";
import { useAuth } from "../../../hooks/auth/AuthContext";
import LoginModal from "../../../shared/login_modal/LoginModal";
import { formatUrlCurrencyLang } from "../../../shared/helpers/general-helpers";

interface Props {
    promoList: any[];
}

const PromoCard = ({ promoList }: Props) => {
    const intl = useIntl();
    const { layoutState } = useLayout();
    const { authState } = useAuth();
    const navigate = useNavigate();
    const [contentModalVisible, setContentModalVisible] = useState(false);
    const [selectedPromo, setSelectedPromo] = useState<any>();
    const [loginModalVIsible, setLoginModalVisible] = useState(false);
    const { setLoading } = useSpinner();

    const onMoreInfoClick = (promo: any) => {
        // if (authState.isLogin) {
            setContentModalVisible(true);
            setSelectedPromo(promo);
        // } else {
        //     setLoginModalVisible(true);
        // }
    }

    const onApplyNowClick = (promo: any) => {
        if (authState.isLogin) {
            if (promo && promo.Bonus && promo.Bonus.length > 0 && promo.Bonus[0].bonusType && promo.Bonus[0].bonusType.id) {
                switch (promo.Bonus[0].bonusType.id) {
                    case 'BONUS_DEPOSIT':
                        navigate(`/${formatUrlCurrencyLang()}/dashboard/1`);
                        break;
                    case 'BONUS_MANUAL':
                        break;
                    case 'BONUS_TRANSFER':
                        navigate(`/${formatUrlCurrencyLang()}/dashboard/2`);
                        break;
                }
            }
        } else {
            setLoginModalVisible(true);
        }
    }

    const handleCancel = () => {
        setContentModalVisible(false);
    }

    return (
        <>
            {promoList && promoList.length > 0 &&
                <div className="promo-list-container">
                    {promoList.map((promo) => {
                        const promoTitle = JSON.parse(promo.title);
                        return <> <div className="promo-item-card">
                            <div className="promo-item-img">
                                <img src={promo.banner ? JSON.parse(promo.banner)[setDBLanguageToGet(layoutState.locale)] : '-'} alt={promoTitle[intl.locale]} />
                            </div>
                            <div className="promo-item-content">
                                <div className="promo-item-content-title">{promo && promo.title ? JSON.parse(promo.title)[setDBLanguageToGet(layoutState.locale)] : '-'}</div>
                                <div className="promo-item-content-desc">{promo && promo.description ? JSON.parse(promo.description)[setDBLanguageToGet(layoutState.locale)] : '-'}</div>
                                <div className="promo-item-content-btn">
                                    <div className="promo-item-content-more-info-btn" onClick={() => { onMoreInfoClick(promo) }}>
                                        <FormattedMessage id={'PROMO_MORE_INFO'} />
                                    </div>
                                    {promo && promo.Bonus && promo.Bonus.length > 0 && promo.Bonus[0].bonusType
                                        && promo.Bonus[0].bonusType.id != 'BONUS_MANUAL' &&
                                        <div className="promo-item-content-apply-now-btn" onClick={() => { onApplyNowClick(promo) }}>
                                            <FormattedMessage id={'PROMO_APPLY_NOW'} />
                                        </div>
                                    }
                                </div>
                                <div className="promo-item-content-remaining">
                                    <div className="promo-item-content-remaining-title">
                                        <FormattedMessage id={'PROMO_REMAIN_TIME'} />
                                    </div>
                                    {(promo.remainingDay && +promo.remainingDay > 0) ?
                                        <div className="promo-item-content-remaining-item">
                                            <FormattedMessage id={'PROMO_REMAIN_TIME_DAY'} values={{
                                                0: promo.remainingDay.toString()
                                            }} />
                                        </div> : <></>
                                    }
                                    {(promo.remainingHour && promo.remainingHour > 0) ?
                                        <div className="promo-item-content-remaining-item">
                                            <FormattedMessage id={'PROMO_REMAIN_TIME_HOUR'} values={{
                                                0: promo.remainingHour.toString()
                                            }} />
                                        </div> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                            <LoginModal modalVisible={{ visible: loginModalVIsible, setVisible: setLoginModalVisible }} />
                        </>
                    })

                    }
                    <Modal
                        className="promo-content-modal"
                        visible={contentModalVisible}
                        closable={true}
                        footer={null}
                        onCancel={handleCancel}
                        destroyOnClose>
                        {
                            selectedPromo && selectedPromo?.editorHTMLContent &&
                            <div className="promo-content-container" dangerouslySetInnerHTML={{
                                __html: JSON.parse(selectedPromo?.editorHTMLContent)[setDBLanguageToGet(layoutState.locale)]
                            }} />
                        }
                    </Modal>
                </div>
            }
        </>
    )
}

export default PromoCard;