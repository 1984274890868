
import "./Auth.less";
import { Steps, Row, Col } from "antd";
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAuth } from '../../hooks/auth/AuthContext';
import useSpinner from '../../hooks/layout/useSpinner';
import useNotification from '../../hooks/layout/useNotification';
import { useIntl } from 'react-intl';
import RegisterStep1 from "./register-components/RegisterStep1";
import RegisterStep2 from "./register-components/RegisterStep2";
import RegisterStep3 from "./register-components/RegisterStep3";
import { useNavigate } from "react-router-dom";
import { useLayout } from "../../hooks/layout/LayoutContext";
import RegisterBanners from "./register-components/RegisterBanners";
import registerBottomBanner from '../../assets/images/register-banners-sg.jpg';
import registerBottomBannerTh from '../../assets/images/register-banners-th.jpg';
import { GET_CONFIGURATIONS } from "./auth.gql";
import _ from "lodash";

const { Step } = Steps;

const Register = () => {
    const { innerWidth: width } = window;
    const { authState, authDispatch } = useAuth();
    const { setLoading } = useSpinner();
    const intl = useIntl();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();
    const { layoutState } = useLayout();
    const [getBottomBanner, { data: getData }] = useLazyQuery(GET_CONFIGURATIONS, { fetchPolicy: "cache-and-network" });
    const [bottomBanner, setBottomBanner] = useState<any>();

    useEffect(() => {
        if (layoutState.locale && authState?.defaultCurrency) {
            const localFromStorage = localStorage.getItem('pfxl') ?? 'en';
            
            getBottomBanner({
                variables: {
                    config: `registerBottomBanner${_.capitalize(authState?.defaultCurrency?.toLowerCase())}${localFromStorage}`,
                }
            })
        }
    }, [layoutState.locale, authState?.defaultCurrency])

    useEffect(()=>{
        if(getData && getData?.configurations) {
            setBottomBanner(getData?.configurations?.value)
        }
    },[getData])

    useEffect(() => {
        if (authState && authState.isLogin) {
            navigate(`dashboard`);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const next = () => {
        setCurrentStep(currentStep + 1);
    };

    const backToFirstPage = () => {
        setCurrentStep(0);
    }

    const steps = [
        // {
        //     content: <RegisterStep1 next={next} step={currentStep + 1} />,
        // },
        {
            content: <RegisterStep2 next={next} step={currentStep + 1} />,
        },
        {
            content: <RegisterStep3 backToFirstPage={backToFirstPage} />,
        },
    ];

    return (
        <div className="register-container">
            <div className="register-wrapper">
                <div className="register-form-container">
                    <div className="register-top-container">
                        <div className="register-form-right-element">
                            <Steps
                                direction={width > 576 ? "horizontal" : "vertical"}
                                current={currentStep}
                                size="default"
                            >
                                {steps.map((item) => (
                                    <Step />
                                ))}
                            </Steps>
                            <div className="register-form-items">{steps[currentStep].content}</div>
                        </div>
                        <div className="register-form-left-element">
                            {/* <img src={layoutState.locale === 'th' ? bannerImgTh : bannerImg} alt="register banner" className="reg-img" /> */}
                            <RegisterBanners />
                        </div>
                    </div>
                    <div className="bottom-banner">
                        {/* {authState?.defaultCurrency === 'THB' ? */}
                            <img src={bottomBanner ?? registerBottomBanner} alt='bottom-banner' /> :
                            {/* <img src={registerBottomBanner} alt='bottom-banner' />
                        } */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
