
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";

import Casino1 from '../../../assets/images/live-casino-1.jpg';
import Casino2 from '../../../assets/images/live-casino-2.jpg';
import Casino3 from '../../../assets/images/live-casino-3.jpg';
import Casino4 from '../../../assets/images/live-casino-4.jpg';
import Casino5 from '../../../assets/images/live-casino-5.jpg';


import ScrollMenuCard from '../../../shared/components/horizontal-scroll-menu/ScrollMenuCard';
import LoginModal from '../../../shared/login_modal/LoginModal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth/AuthContext';
import useSpinner from '../../../hooks/layout/useSpinner';
import useNotification from '../../../hooks/layout/useNotification';
import { useMutation } from '@apollo/client';
import { GET_GAME_URL } from '../../provider-game-page/Game.gql';
import { checkIsGameUnderMaintenance, formatUrlCurrencyLang } from '../../../shared/helpers/general-helpers';
import TransferModal from '../../../shared/transfer-modal/TransferModal';

SwiperCore.use([Autoplay]);

interface Props {
    sgdList: any;
    thbList: any;
    label: any;
}

const HomeMoreGamesCasino = ({ sgdList, thbList, label }: Props) => {
    const [selected, setSelected] = useState<string>('');
    const navigate = useNavigate();
    const { authState } = useAuth();
    const [loginModalVIsible, setLoginModalVisible] = useState(false);
    const { setLoading } = useSpinner();
    const intl = useIntl();
    const { setErrorNotification } = useNotification();
    const [getGameUrl, { data: gameData, loading: gameLoding, error: gameError }] = useMutation(GET_GAME_URL,
        { fetchPolicy: "no-cache" });
    const [selectedGameUrl, setSelectedGameUrl] = useState<any>();
    const [transferModalVisible, setTransferModalVisible] = useState(false);
    const [selectedGameCode, setSelectedGameCode] = useState('');
    const [selectedGameProviderCode, setSelectedGameProviderCode] = useState('');
    const [selectedGame, setSelectedGame] = useState<any>();

    useEffect(() => {
        if (gameLoding)
            setLoading(true);
        else
            setLoading(false); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameLoding])

    const onGameCardClick = (gameCode: string, gameProviderCode: string, game: any) => {
        if (authState.isLogin) {
            // const promptWindow = window.open(gameCode, "_blank");
            // if (promptWindow)
            //     setPopupWindow(promptWindow);

            // setSelectedGame(game);
            // setSelectedGameProviderCode(gameProviderCode);
            // setSelectedGameCode(gameCode);
            // setTransferModalVisible(true);

            getGameUrl({
                variables: {
                    gameUrlInput: {
                        gameProviderCode: gameCode,
                        platform: 'web',
                        lang: intl.locale,
                        isFreeGame: 'false',
                        gameCode: gameCode
                    }
                }
            });
        }
        else
            setLoginModalVisible(true);
    }

    useEffect(() => {
        if (gameData && gameData.gameUrl && gameData.gameUrl.url) {
            // if (popupWindow) {
            //     popupWindow.location.href = gameData.gameUrl.url;
            window.open(gameData.gameUrl.url, "gameTab");
            //}

            // setSelectedGameUrl(gameData.gameUrl.url);
            // setTransferModalVisible(true);
        } else {
            // if (popupWindow) {
            //     popupWindow.close();
            // }
        }
    }, [gameData]);

    const handleItemClick = (itemId: string) => () => {
        setSelected(selected !== itemId ? itemId : "");
    };

    // const casinoList = [
    //     {
    //         id: '1',
    //         name: 'SA Gaming',
    //         imgUrl: Casino1,
    //         gameCode: 'SAGAMING',
    //         providerId: 'SAGAMING',
    //         providerName: 'SA Gaming'
    //     },
    //     {
    //         id: '2',
    //         name: 'Sexy',
    //         imgUrl: Casino2,
    //         gameCode: 'SEXYBACARRAT',
    //         providerId: 'SEXYBACARRAT',
    //         providerName: 'Sexy'
    //     },
    //     {
    //         id: '3',
    //         name: 'Evolution',
    //         imgUrl: Casino3,
    //         gameCode: 'EVOLUTION',
    //         providerId: 'EVOLUTION',
    //         providerName: 'Evolution'
    //     },
    //     {
    //         id: '4',
    //         name: 'Opus',
    //         imgUrl: Casino4,
    //         gameCode: 'OPUSLIVECASINO',
    //         providerId: 'OPUSLIVECASINO',
    //         providerName: 'Opus'
    //     },
    //     {
    //         id: '5',
    //         name: 'WM',
    //         imgUrl: Casino5,
    //         gameCode: 'WMLIVECASINO',
    //         providerId: 'WMLIVECASINO',
    //         providerName: 'WM'
    //     }
    // ]

    const onMoreGamesClick = () => {
        if (authState.isLogin)
            navigate(`landing/slots`);
        else
            setLoginModalVisible(true);
    }

    return (
        <>
            <div className="home-more-games-container">
                {/* <div className='home-more-games-title-container'>
                    <div className='home-more-games-btn' onClick={onMoreGamesClick}>
                        <FormattedMessage id={'HOME_MORE_GAMES_TITLE'} />
                    </div>
                </div> */}
                <div className='more-games-title'><FormattedMessage id={label} /></div>
                <div className='home-more-games-carousel-container'>
                    <Swiper
                        slidesPerView={5}
                        spaceBetween={30}
                        autoplay={true}
                        loop={true}
                        modules={[Pagination]}
                        className="mySwiper">
                        {(authState?.defaultCurrency === 'THB' ? thbList : sgdList)
                            .map((item: any) => (
                                <SwiperSlide key={item.id}>
                                    <ScrollMenuCard
                                        fromMoreGames={authState?.defaultCurrency === 'SGD'}
                                        title={item.name}
                                        itemId={item.id}
                                        key={item.id}
                                        onClick={!checkIsGameUnderMaintenance(item, authState.maintenanceList ?? []) ? () => { onGameCardClick(item.gameCode, item.providerId, item) } : () => { }}
                                        selected={item.id === selected}
                                        img={item.imgUrl}
                                        showRibbon={false}
                                        hasHoverCover
                                        // hasTitleInfo
                                        maintenance={checkIsGameUnderMaintenance(item, authState.maintenanceList ?? [])}
                                        // infoContent={
                                        //     <div className='more-games-info'>
                                        //         <div className='info-first'>
                                        //             <div className='first-rtp-container'>
                                        //                 <div className='first-rtp'><FormattedMessage id='RTP' /></div>
                                        //                 <div className='first-rtp-rate'>96.93</div>
                                        //             </div>
                                        //             <div className='first-line-container'>
                                        //                 <div className='first-line'><FormattedMessage id='LINE' /></div>
                                        //                 <div className='first-line-rate'>-</div>
                                        //             </div>
                                        //         </div>
                                        //         <div className='info-second'>
                                        //             <div className='second-cascade-container'>
                                        //                 <div className='second-cascade'><FormattedMessage id='CASCADE_WIN' /></div>
                                        //                 <div className='second-cascade-rate'>-</div>
                                        //             </div>
                                        //         </div>
                                        //         <div className='info-third'>
                                        //             <div className='third-volatility-container'>
                                        //                 <div className='third-volatility'><FormattedMessage id='VOLATILITY' /></div>
                                        //                 <div className='third-volatility-rate'>Medium</div>
                                        //             </div>
                                        //         </div>
                                        //     </div>
                                        // }
                                        content={
                                            <>
                                                <div className={checkIsGameUnderMaintenance(item, authState.maintenanceList ?? []) ? 'more-games-swiper-content-container-inactive' : 'more-games-swiper-content-container'}>
                                                    <div className='more-games-swiper-content-title'>{item.providerName}</div>
                                                </div>
                                                {/* <div className='more-games-rtp-container'>
                                                    <div className='more-games-rtp'>
                                                        <div className='rtp-lbl'><FormattedMessage id='RTP' /></div>
                                                        <div className='rtp-val'>96.93</div>
                                                    </div>
                                                </div> */}
                                            </>
                                        }
                                    />
                                </SwiperSlide>
                            ))}
                    </Swiper>
                </div>
            </div>
            <LoginModal modalVisible={{ visible: loginModalVIsible, setVisible: setLoginModalVisible }} />
            {/* <TransferModal providerId={selectedGame?.providerId} gameUrl={selectedGameCode} onMenuRedirectClick={openGame} modalVisible={{ visible: transferModalVisible, setVisible: setTransferModalVisible }} /> */}
        </>

    )
}

export default HomeMoreGamesCasino;