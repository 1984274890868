import SwiperCore, { Autoplay } from "swiper";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { checkIsGameAvailable } from "../../../shared/helpers/general-helpers";
import { FormattedMessage } from "react-intl";
import footballBg from '../../../assets/images/world-cup-bg.png';
import { format } from "date-fns";
import '../Home.less';

SwiperCore.use([Autoplay]);

interface Props {
    game: any;
    onGameClick: any;
}

const HomeUpcomingMatchCard = ({ game, onGameClick }: Props) => {
    const { authState } = useAuth();

    return (
        <>
            <div className="m-upcoming-match-game-card">
                <div className="header-container">
                    <FormattedMessage id='UPCOMING_SUPPORT' />
                </div>
                <div className="game-detail">
                    <div className="home-team">
                        <div className="team-top-container">
                            <div className="team-logo-container">
                                <img className="logo" src={game.homeLogo} />
                            </div>
                        </div>
                        <div className="team-name">{game.homeName}</div>
                    </div>
                    <div className="bet-btn-section">
                        <div className="game-time-container">
                            <div className="game-date">{format(new Date(game.gameDate), "dd/MM/yyyy")}</div>
                            <div className="game-time">{game.gameTime ?
                                game.gameTime.substring(0, game.gameTime.indexOf(" "))
                                : '-'}</div>
                        </div>
                        <div className="vs"><FormattedMessage id='UPCOMING_MATCHES_VS' /></div>
                        <div className="bet-btn" onClick={checkIsGameAvailable(game, authState?.maintenanceList ?? []) ? () => { onGameClick(game) } : () => { }}><FormattedMessage id='UPCOMING_MATCHES_BET' /></div>
                    </div>
                    <div className="away-team">
                        <div className="team-top-container">
                            <div className="team-logo-container">
                                <img className="logo" src={game.awayLogo} />
                            </div>
                        </div>
                        <div className="team-name">{game.awayName}</div>
                    </div>
                </div>
                <div className="game-odds-container">
                    <div className="game-odds-team">
                        <div className="team-odd">{game.homeOdd}</div>
                        <div className="team-home"><FormattedMessage id={'UPCOMING_MATCHES_HOME'} /></div>
                    </div>
                    <div className="game-odds-team">
                        <div className="team-odd">{game.gameOdd}</div>
                        <div className="team-home"><FormattedMessage id={'UPCOMING_MATCHES_HDP'} /></div>
                    </div>
                    <div className="game-odds-team">
                        <div className="team-odd">{game.awayOdd}</div>
                        <div className="team-home"><FormattedMessage id={'UPCOMING_MATCHES_AWAY'} /></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeUpcomingMatchCard;