import { SHOW_MODAL, useLayout } from "./LayoutContext";

const useModal = () => {
    const { layoutState, layoutDispatch } = useLayout();

    const setSuccessModal = (description: string, message?: string) => {
        layoutDispatch({ type: SHOW_MODAL, payload: { type: 'success', message: message || 'Successful', description } });
    }

    const setErrorModal = (description: string, message?: string) => {
        layoutDispatch({ type: SHOW_MODAL, payload: { type: 'error', message: message || 'Error', description } });
    }

    return {
        setSuccessModal, setErrorModal
    }
}

export default useModal;