
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";

import ScrollMenuCard from '../../../shared/components/horizontal-scroll-menu/ScrollMenuCard';
import LoginModal from '../../../shared/login_modal/LoginModal';
import { useAuth } from '../../../hooks/auth/AuthContext';
import useSpinner from '../../../hooks/layout/useSpinner';
import { GET_PROMOTION_THUMBNAILS } from '../../promo/Promo.gql';
import { setDBLanguageToGet } from '../../../shared/helpers/language.helpers';
import { useLayout } from '../../../hooks/layout/LayoutContext';
import { useQuery } from '@apollo/client';
import '../Home.less';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { formatUrlCurrencyLang } from '../../../shared/helpers/general-helpers';

SwiperCore.use([Autoplay]);

const HomePromos = () => {
    const navigate = useNavigate();
    const { layoutState } = useLayout();
    const { authState } = useAuth();
    const [loginModalVIsible, setLoginModalVisible] = useState(false);
    const { setLoading } = useSpinner();
    const [promotionList, setPromotionList] = useState<any>();
    const { data, loading } = useQuery(GET_PROMOTION_THUMBNAILS, {
        variables: { currency: authState?.defaultCurrency },
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (data) {
            const _datas = data.promotionThumbnails && data.promotionThumbnails.promotions && data.promotionThumbnails.promotions.length > 0 ?
                data.promotionThumbnails.promotions.map((val: any) => {
                    return {
                        ...val,
                        key: val?.title && JSON.parse(val?.title)[setDBLanguageToGet(layoutState.locale)]
                    };
                }) : [];
            setPromotionList(_datas);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // useEffect(() => {
    //     if (loading)
    //         setLoading(true);
    //     else
    //         setLoading(false); // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [loading])

    return (
        <div className="promo-section-carousel-container">
            {
                loading ? <Spin /> :
                    promotionList && promotionList.length > 0 &&
                    <div className="home-promo-container">
                        <div className='home-promo-carousel-container'>
                            <Swiper
                                slidesPerView={5}
                                spaceBetween={10}
                                autoplay={true}
                                loop={true}
                                modules={[Pagination]}
                                className="mySwiper">
                                {promotionList?.map((item: any) => {
                                    if (item?.thumbnail && JSON.parse(item?.thumbnail)[setDBLanguageToGet(layoutState.locale)]) 
                                        return <SwiperSlide key={item?.key}>
                                            <ScrollMenuCard
                                                itemId={item?.key}
                                                key={item?.key}
                                                onClick={() => { navigate(`promotions`) }}
                                                selected={true}
                                                img={JSON.parse(item?.thumbnail)[setDBLanguageToGet(layoutState.locale)]}
                                                showRibbon={false}
                                            />
                                        </SwiperSlide>
                                })}
                            </Swiper>
                        </div>
                    </div>
            }

            <LoginModal modalVisible={{ visible: loginModalVIsible, setVisible: setLoginModalVisible }} />
            {/* <TransferModal providerId={selectedGame?.providerId} gameUrl={selectedGameCode} onMenuRedirectClick={openGame} modalVisible={{ visible: transferModalVisible, setVisible: setTransferModalVisible }} /> */}
        </div>

    )
}

export default HomePromos;