

import '../../DashboardMobile.less';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
    icon: string;
    text: string;
    changeTab: (value: string) => void;
    keys: string;
}

const DashboardMobileTopButton = ({ icon, text, changeTab, keys }: Props) => {
    const intl = useIntl();
    return (
            <div className='m-dashboard-wallets-buttons' onClick={() => changeTab(keys)}>
                <img src={icon} alt={intl.formatMessage({ id: text })} className="m-dashboard-wallets-icon" />
                <div className='m-dashboard-wallets-buttons-text'><FormattedMessage id={text} /></div>
            </div>
    )
}

export default DashboardMobileTopButton;