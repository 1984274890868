import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const setTokenCookie = (key: string, value: string, domain: string = '') => {
    const isProd = process.env.NODE_ENV === 'production';
    cookies.set(key, value,
        {
            maxAge: key === (process.env.REACT_APP_ACCESS_TOKEN || 'sc-at') ? 3600 : 18000, //refresh token keep for a week
            domain: domain ? domain : isProd ? window.location.hostname : 'localhost',
            secure: isProd ? true : undefined,
            sameSite: isProd ? 'lax' : undefined,
            path: '/'
        });
}

export const clearAllTokens = () => {
    cookies.remove(process.env.REACT_APP_ACCESS_TOKEN || 'gmf-at', removeCookieToptions());
    cookies.remove(process.env.REACT_APP_REFRESH_TOKEN || 'gmf-rt', removeCookieToptions());
    cookies.remove('gmf-sid', removeCookieToptions());
}

const removeCookieToptions = () => {
    const isProd = process.env.NODE_ENV === 'production';
    return {
        expires: new Date(Date.now() + 500),
        domain: isProd ? window.location.hostname : 'localhost',
        secure: isProd ? true : undefined,
        path: '/'
    }
}