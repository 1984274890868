import { gql } from "@apollo/client";

export const GET_GAME_URL = gql`
mutation GameUrl($gameUrlInput:GameUrlInput!){
    gameUrl(gameUrlInput:$gameUrlInput){
        status,
        url,
        token,
        isLineBrowser
    }
}
`

export const GET_SLOT_GAME_LIST = gql`
query SlotGameList($slotGameListInput:SlotGameListInput!){
    slotGameList(slotGameListInput:$slotGameListInput){
        gameCategory,
        gameList
    }
}
`

export const GET_FISH_GAME_LIST = gql`
query FishGameList($fishGameListInput:FishGameListInput!){
    fishGameList(fishGameListInput:$fishGameListInput){
        gameList
    }
}
`

export const GET_INSTANT_WIN_GAME_LIST = gql`
query InstantWinGameList($instantWinGameListInput:InstantWinGameListInput!){
    instantWinGameList(instantWinGameListInput:$instantWinGameListInput){
        gameCategory,
        gameList
    }
}
`

export const GET_CMD_LIVE_MATCH = gql`
query Matches{
    matches {
        matchID,
        matchDate,
        leagueID,
        leagueName,
        homeTeamID,
        homeTeamName,
        homeImgUrl,
        awayTeamID,
        awayTeamName,
        awayImgUrl,
        homeOdds,
        awayOdds,
        odds
    }
}
`