import { gql } from "@apollo/client";

export const GET_WALLETS = gql`
query Wallets{
    wallets{
        wallets{
            id,
            walletType,
            userWalletType{
                id,
                displayName,
                displayProductId,
                gameProvider {
                    id,
                    name,
                    code
                },
                gameProviderCodeId
            }
            balance,
            depositable,
            withdrawable,
            isMaintenance,
            isBonus
        },
        userBonusTracker{
            userDepositBonus{
                bonusSetting{
                    BonusSettingWalletType{
                        walletType{
                            id
                        }
                    }
                }
            }
        },
        walletCategory {
            seq,
            productName,
            labelName,
            bal
        }
    }
}
`;

export const SUBMIT_TRANSFER = gql`
    mutation Transfer($transferInput: TransferInput!) {
        transfer(transferInput: $transferInput) {
            status
            id,
        }
    }
`;

export const SUBMIT_TRANSFER_ALL = gql`
    mutation TransferAll($transferAllInput: TransferAllInput!) {
        transferAll(transferAllInput: $transferAllInput) {
            status
            id,
        }
    }
`;

export const GET_BONUS_FREE_BET = gql`
query BonusesFreeBet {
    bonusesFreeBet {
        id,
        minAmountRequired,
        title,
        givingAmount,
        bonus{
            coverageTime,
        },
        maxGiven,
        BonusSettingWalletType{
            walletType{
                id,
                displayName,
                displayProductId
            }
        }
    }
}
`;

export const SUBMIT_BONUS_FREE_BET = gql`
mutation CreateFreeBetBonus($freeBetBonusInput: FreeBetBonusInput!) 
{
    createFreeBetBonus(freeBetBonusInput: $freeBetBonusInput) 
    {
        status
    }
}
`;

export const GET_TRANSFER_BONUSES = gql`
query BonusesTransfer{
    bonusesTransfer{
        id,
        minAmountRequired,
        title,
        givingAmount,
        bonus{
            coverageTime,
        },
        maxGiven,
        BonusSettingWalletType{
            walletType{
                id,
                displayName,
                displayProductId
            }
        }
    }
}
`;