import { gql } from '@apollo/client';

export const GET_MESSAGES = gql`
query Messages($pageNo:Int,$pageSize:Int!,$category:String!){
    messages(pageNo:$pageNo,pageSize:$pageSize,category:$category){
        count,
        messages{      
            id,
            title,
            content,
            messageTypeEnum {
                id
            },
            messageSendTypeEnum {
                id,
            },
            createdAt,
            userInteractedMessage {
                id,
                read,
                deleted
            },
            userTransactionalMessage {
                id,
                read,
                deleted,
                refId
            }
        }
    }
}
`;

export const GET_MESSAGE = gql`
query Message($id:String!){
    message(id:$id){
        id,
        title,
        content,
        messageTypeEnum {
            id,                
            read,
            deleted
        },
        messageSendTypeEnum {
            id
        },
        createdAt,
    }
}
`;

export const GET_UNREAD_MESSAGE = gql`
query UnreadMessageCount {
    unreadMessageCount {
        systemUnread, 
        infoUnread, 
        paymentUnread
    }
}
`;

export const CREATE_MESSAGE = gql`
mutation CreateMessage($messageCreateInput:MessageCreateInput!){
    createMessage(messageCreateInput:$messageCreateInput){
        status,
        id,
        errorMessage
    }
}
`;

export const EDIT_MESSAGE = gql`
mutation EditMessage($messageEditInput:MessageEditInput!){
    editMessage(messageEditInput:$messageEditInput){
        status,
        id,
        errorMessage
    }
}
`;

export const DELETE_MESSAGE = gql`
mutation DeleteMessage($id:String!){
    deleteMessage(id:$id){
        status,
        id
    }
}`;

export const MESSAGE_ACTIONS = gql`
mutation MessageActions($id:[String]!,$action:String!){
    messageActions(id:$id,action:$action){
        status,
        id
    }
}`;

export const GET_MESSAGE_TYPE = gql`
query MessageType{
    messageType{
        id
    }
}
`;

export const GET_MESSAGE_SEND_TYPE = gql`
query MessageSendType{
    messageSendType{
        id
    }
}
`;