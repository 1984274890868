import { Tabs } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import '../../../../DashboardMobile.less';
import './DashboardMobileTopInnerMenu.less';

const { TabPane } = Tabs;

interface Props {
    elementList: any[];
    needTranslation?: boolean;
}

const DashboardMobileTopInnerMenu = ({ elementList, needTranslation = true }: Props) => {
    const intl = useIntl();

    return (
        <div className="m-dashboard-top-inner-menu-container">
            <Tabs tabPosition={'top'}>
                {
                    elementList.map((child) => {
                        return (
                            <TabPane tab={needTranslation ? <FormattedMessage id={child.tabName} /> :
                                child.count ?
                                    <div className='inner-menu-tabname-container'>
                                        <div className='inner-menu-tabname-name'>{child.tabName}</div>
                                        <div className='inner-menu-tabname-count'>({child.count})</div>
                                    </div>
                                    : child.tabName}
                                key={child.key}>
                                {child.element}
                            </TabPane>
                        );
                    })
                }
            </Tabs>
        </div>
    )
}

export default DashboardMobileTopInnerMenu;