
import { Divider } from 'antd';
import '../DashboardMobile.less';
import './DashboardMobileWallets.less';
import DashboardMobileTopButtons from './components/DashboardMobileTopButtons';
import DashboardMobileTopWallet from './components/DashboardMobileTopWallet';
import DashboardMobileTopProfile from './components/DashboardMobileTopProfile';

interface Props {
    setActiveTabKey?: any;
    isFromMainPage?: boolean;
    showLeftElement?: boolean;
}

const DashboardMobileTop = ({ setActiveTabKey, showLeftElement = true, isFromMainPage = false }: Props) => {
    return (
        <div className={showLeftElement ? '' :'m-dashboard-mobile-top-container'}>
            {!showLeftElement &&
                <DashboardMobileTopWallet />
            }
            <div className="m-dashboard-wallets-container">
                {
                    showLeftElement &&
                    <>
                        <DashboardMobileTopProfile />
                        <Divider type='vertical' className='m-dashboard-wallets-divider' />
                    </>
                }
                <DashboardMobileTopButtons setActiveTabKey={setActiveTabKey} isFromMainPage={isFromMainPage} />
            </div>
        </div>
    )
}

export default DashboardMobileTop;