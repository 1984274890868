import { FormattedMessage } from 'react-intl';
import './FooterMobile.less';

import iconHome from '../../../assets/images/icon-home-active.png';
import iconPromo from '../../../assets/images/icon-rewards.png';
import iconSupport from '../../../assets/images/icon-support.png';
import iconProfile from '../../../assets/images/icon-profile.png';
import iconBanking from '../../../assets/images/icon-banking.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth/AuthContext';
import { formatUrlCurrencyLang } from '../../helpers/general-helpers';

const FooterMobile = () => {
    const navigate = useNavigate();
    const { authState } = useAuth();
    const [homeFocus, setHomeFocus] = useState(true);
    const [promoFocus, setPromoFocus] = useState(false);
    const [walletFocus, setWalletFocus] = useState(false);
    const [supportFocus, setSupportFocus] = useState(false);
    const [profileFocus, setProfileFocus] = useState(false);

    const onElementFocus = (element: string) => {
        switch (element) {
            case 'home':
                setHomeFocus(true);
                setPromoFocus(false);
                setWalletFocus(false);
                setSupportFocus(false);
                setProfileFocus(false);
                navigate(`/`);
                break;
            case 'promo':
                setHomeFocus(false);
                setPromoFocus(true);
                setWalletFocus(false);
                setSupportFocus(false);
                setProfileFocus(false);
                navigate(`promotions`);
                break;
            case 'wallet':
                setHomeFocus(false);
                setPromoFocus(false);
                setWalletFocus(true);
                setSupportFocus(false);
                setProfileFocus(false);
                authState.isLogin ?
                    navigate('dashboard/banking') :
                    navigate('/');
                break;
            case 'support':
                setHomeFocus(false);
                setPromoFocus(false);
                setWalletFocus(false);
                setSupportFocus(true);
                setProfileFocus(false);
                break;
            case 'profile':
                setHomeFocus(false);
                setPromoFocus(false);
                setWalletFocus(false);
                setSupportFocus(false);
                setProfileFocus(true);
                authState.isLogin ?
                    navigate('dashboard/profile') :
                    navigate('/');
                break;
            default:
                setHomeFocus(false);
                setPromoFocus(false);
                setWalletFocus(false);
                setSupportFocus(false);
                setProfileFocus(false);
                break;
        }
    }

    return (
        <div className="footer-mobile-container">
            <div className={homeFocus ? 'footer-mobile-item-container-focus' : 'footer-mobile-item-container'} onClick={() => onElementFocus('home')}>
                <img src={iconHome} alt="gaming-logo" className="footer-mobile-icon" />
                <div className='footer-mobile-item-title'><FormattedMessage id={'M_FOOTER_HOME'} /></div>
            </div>
            <div className={promoFocus ? 'footer-mobile-item-container-focus' : 'footer-mobile-item-container'} onClick={() => onElementFocus('promo')}>
                <img src={iconPromo} alt="gaming-logo" className="footer-mobile-icon" />
                <div className='footer-mobile-item-title'><FormattedMessage id={'M_FOOTER_PROMO'} /></div>
            </div>
            <div className={walletFocus ? 'footer-mobile-item-container-focus' : 'footer-mobile-item-container'} onClick={() => onElementFocus('wallet')}>
                <img src={iconBanking} alt="gaming-logo" className="footer-mobile-icon" />
                <div className='footer-mobile-item-title'><FormattedMessage id={'M_FOOTER_BANKING'} /></div>
            </div>
            {/* <div className={supportFocus ? 'footer-mobile-item-container-focus' : 'footer-mobile-item-container'} onClick={() => onElementFocus('support')}>
                <img src={iconSupport} alt="gaming-logo" className="footer-mobile-icon" />
                <div className='footer-mobile-item-title'><FormattedMessage id={'M_FOOTER_SUPPORT'} /></div>
            </div> */}
            <div className={profileFocus ? 'footer-mobile-item-container-focus' : 'footer-mobile-item-container'} onClick={() => onElementFocus('profile')}>
                <img src={iconProfile} alt="gaming-logo" className="footer-mobile-icon" />
                <div className='footer-mobile-item-title'><FormattedMessage id={'M_FOOTER_PROFILE'} /></div>
            </div>
            <div className={supportFocus ? 'footer-mobile-item-container-focus' : 'footer-mobile-item-container'} onClick={() => onElementFocus('support')}>
                <img src={iconSupport} alt="gaming-logo" className="footer-mobile-icon" />
                <div className='footer-mobile-item-title'><FormattedMessage id={'M_FOOTER_LIVE_CHAT'} /></div>
            </div>
        </div>
    )
}

export default FooterMobile;