import { gql } from "@apollo/client";

export const GET_USER_PROFILE = gql`
query UserProfile {
    userProfile {
		id,
		name,
		userName,
		phone,
		phoneVerified,
		email,
		emailVerified,
		gender,
		bankAccountStatus,
		loginPasswordStatus,
		isActive,
		requiredPasswordChange,
		address1,
		address2,
		city,
		state,
		postcode,
		dob,
		totalBalance,
		currency,
		referralCode,
		siteUrl,
		dobUpdated,
		registerTime,
		ua,
		currentTime,
		kycApproved,
		kycStatus,
		userInteractedPopups {
			id,
			popUpTypeId
		}

    }
}
`;

export const GET_MAINTENANCE_LIST = gql`
query MaintenanceList {
    maintenanceList {
		providerIdList
    }
}
`;

export const GET_RED_PACKET = gql`
query BonusesRedPacket {
    bonusesRedPacket {
		id
    }
}
`;

export const CLAIM_RED_PACKET = gql`
mutation UpdateRedPacketBonus {
	updateRedPacketBonus{
		status,
		a,
		id
	}
}
`;

export const GET_HOME_POPUP_BANNER = gql`
query HomePopUpBanner{
    homePopUpBanner {
            id,
            title,
            pictureUrl,
            orientation
    }
  }
  `;