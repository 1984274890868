
import { FormattedMessage, useIntl } from "react-intl";

import VideoIcon from '../../../assets/images/icon-highlightsvideo.png';
import VideoImg from '../../../assets/images/highlights-video.jpg';
import VideoPlayBtn from '../../../assets/images/icon-play.png';

import HomeSectionTitle from "./Home-Section-Title";
import { useLazyQuery } from "@apollo/client";
import { GET_CONFIGURATIONS } from "../../auth/auth.gql";
import { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import _ from "lodash";

const VideoCard = () => {
    const intl = useIntl();
    const { layoutState } = useLayout();
    const { authState } = useAuth();
    const [getVideoConfigurations, { data: getData }] = useLazyQuery(GET_CONFIGURATIONS, { fetchPolicy: "cache-and-network" });
    const [video, setVideo] = useState<any>();

    useEffect(() => {
        if (layoutState.locale && authState?.defaultCurrency) {
            const localFromStorage = localStorage.getItem('pfxl') ?? 'en';

            getVideoConfigurations({
                variables: {
                    config: `registerVideoLink${_.capitalize(authState?.defaultCurrency?.toLowerCase())}${localFromStorage}`,
                }
            })
        }
    }, [layoutState.locale, authState?.defaultCurrency])

    useEffect(()=>{
        if(getData && getData?.configurations) {
            setVideo(getData?.configurations?.value)
        }
    },[getData])

    return (
        <div className="video-card-container">
            {/* <HomeSectionTitle icon={VideoIcon} title={'HOME_VIDEO_TITLE'} isH1 /> */}
            {/* <div className="video-card-content"> */}
                <div className="video-card-player">
                    <iframe
                        className="video-card-player-iframe"
                        //src={`https://ib8generalprod.blob.core.windows.net/content/IB8%20branding%20${intl.formatMessage({id: 'HOME_VIDEO'})}.mp4`}
                        src={video ?? intl.formatMessage({ id: 'HOME_VIDEO_LINK' })}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                    {/* <img className="home-video-img" src={VideoImg} alt="video"/> */}
                    {/* <img className="home-video-play-btn" src={VideoPlayBtn} alt="video play button"/> */}
                {/* </div> */}
                {/* <div className="video-card-player-content">
                    <div className="video-card-content-paragraph-1"><FormattedMessage id='HOME_VIDEO_CONTENT_1' /></div>
                    <div className="video-card-content-paragraph-2"><FormattedMessage id='HOME_VIDEO_CONTENT_CLICK' /></div> 
                </div> */}
            </div>
        </div>
    )
}

export default VideoCard;