import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import '../GeneralFooter.less';

const FooterFirstRowLeft = () => {
    
    const onRedirectClick = () => {
        window.open("https://www.aff.ib8sgd.com/");
    }

    return (
        <div className="footer-first-row-left-element">
            <div className='footer-first-row-left-element-items'>
                <Link to={'about-us'}><FormattedMessage id='FOOTER_ABOUT_US' /></Link>
            </div>
            {/* <div className='footer-first-row-left-element-items'>
                <Link to={''}><FormattedMessage id='FOOTER_LIVE_CHAT' /></Link>
            </div> */}
            {/* <div className='footer-first-row-left-element-items'>
                <Link to={'/home/info-centre'}><FormattedMessage id='FOOTER_INFO_CENTRE' /></Link>
            </div> */}
            <div className='footer-first-row-left-element-items'>
                <Link to={'faq'}><FormattedMessage id='FOOTER_FAQ' /></Link>
            </div>
            <div className='footer-first-row-left-element-items'>
                <Link to={'contact-us'}><FormattedMessage id='FOOTER_CONTACT_US' /></Link>
            </div>
            <div className='footer-first-row-left-element-item-last'>
                <Link to={'terms-and-conditions'}><FormattedMessage id='FOOTER_TERMS' /></Link>
            </div>
            <div className='footer-first-row-left-element-item-last'>
                <Link to={'privacy-policy'}><FormattedMessage id='FOOTER_PRIVACY' /></Link>
            </div>
            <div className='footer-first-row-left-element-item-last' onClick={onRedirectClick}>
                <FormattedMessage id='FOOTER_AFFILIATE' />
            </div>
        </div>
    )
}

export default FooterFirstRowLeft;