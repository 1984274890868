import React, { createContext, useContext, useEffect, useReducer } from "react";

interface LayoutState {
    locale: string,
    currency: string,
    showLoading: boolean,
    showNotification: { message: string | undefined, type: 'success' | 'info' | 'warning' | 'error', description: string | undefined },
    showModal: { title: string | undefined, message: string | undefined, type: 'success' | 'info' | 'warning' | 'error', description: string | undefined }
}

const INITIAL_STATE: LayoutState = {
    locale: 'en',
    currency: 'THB',
    showLoading: false,
    showNotification: { message: undefined, type: 'success', description: undefined },
    showModal: { title: undefined, message: undefined, type: 'info', description: undefined }
};

export const SET_LANGUAGE = "[LAYOUT] SET_LANGUAGE";
export const SHOW_LOADING = '[LAYOUT] SHOW_LOADING';
export const SHOW_NOTIFICATION = "[LAYOUT] SHOW_NOTIFICATION";
export const SHOW_MODAL = "[LAYOUT] SHOW_MODAL";
export const CLEAR_NOTIFICATION = "[LAYOUT] CLEAR_NOTIFICATION";
export const SET_CURRENCY = "[LAYOUT] SET_CURRENCY";

const reducer = (state: LayoutState, action: { type: string, payload: any }): LayoutState => {
    const { type, payload } = action;
    switch (type) {
        case SET_LANGUAGE:
            return { ...state, locale: payload };
        case SHOW_LOADING:
            return { ...state, showLoading: payload };
        case SHOW_NOTIFICATION:
            return { ...state, showNotification: payload };
        case SHOW_MODAL:
            return { ...state, showModal: payload };
        case CLEAR_NOTIFICATION:
            return { ...state, showNotification: INITIAL_STATE.showNotification };
        case SET_CURRENCY:
            return { ...state, currency: payload };
        default:
            return state;
    }
};


const layoutContext = createContext<{ layoutState: LayoutState; layoutDispatch: React.Dispatch<any>; }>
    ({ layoutState: INITIAL_STATE, layoutDispatch: () => null });

export const LayoutProvider = ({ children }: any) => {
    const [layoutState, layoutDispatch] = useReducer(reducer, INITIAL_STATE);

    //First Load only.
    useEffect(() => {
        const localFromStorage = localStorage.getItem('pfxl');
        const currencyFromStorage = localStorage.getItem('pfxc');

        if (localFromStorage) {
            layoutDispatch({ type: SET_LANGUAGE, payload: localFromStorage });
        }

        if (currencyFromStorage) {
            layoutDispatch({ type: SET_CURRENCY, payload: currencyFromStorage });
        }

    }, []);

    return (
        <layoutContext.Provider value={{ layoutState, layoutDispatch }} >
            {children}
        </layoutContext.Provider>
    );
}

export const useLayout = () => {
    return useContext(layoutContext);
}
