


import { Modal } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import './PopUpModal.less';
import Logo from '../../../assets/images/logo.svg';
import { useIntl } from 'react-intl';

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
    component: any;
    from?: any;
}

const PopUpModal = ({ modalVisible, component, from }: Props) => {
    const intl = useIntl();
    const modalClassName = from ? `${from}-popup-modal` : 'popup-modal'

    return (
        <>
            <Modal
                className={modalClassName}
                visible={modalVisible.visible}
                closable={true}
                footer={null}
                onCancel={() => modalVisible.setVisible(false)}
                destroyOnClose
            >
                <div className="popup-title">
                    <img className="popup-logo" src={Logo} alt={intl.formatMessage({ id: "SEO_LOGO" })} />
                </div>
                <div className="popup-divider"></div>
                {component}
            </Modal>
        </>
    )
}

export default PopUpModal;