

import HomeHotGames from './Home-Hot-Games';
import HomeMoreGames from './Home-More-Games';
import HomeMoreGamesCasino from './Home-More-Games-Casino';

interface Props {
    sgdList: any;
    thbList: any;
    label: any;
}

const BottomSectionCarouselsCasino = ({ sgdList, thbList, label }: Props) => {
    return (
        <div className="bottom-section-carousels-container">
            {/* <HomeHotGames /> */}
            <HomeMoreGamesCasino sgdList={sgdList} thbList={thbList} label={label} />
        </div>
    )
}

export default BottomSectionCarouselsCasino;