import { Modal } from "antd";
import { ReactChild, ReactFragment, ReactPortal, useState } from "react";
import Marquee from "react-fast-marquee";
import AnnouncementIcon from '../../../assets/images/icon-announcement.png';
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { setDBLanguageToGet } from "../../../shared/helpers/language.helpers";
import Logo from "../../../assets/images/logo.svg";
import { FormattedMessage, useIntl } from 'react-intl';

const MarqueeComponent = ({ marqueeData }: any) => {
    const intl = useIntl();
    const [marqueeModalVisible, setMarqueeModalVisible] = useState(false);
    const { layoutState } = useLayout();
    const [selectedMarqueeId, setSelectedMarqueeId] = useState('');

    const onMarqueeItemClick = (id: string) => {
        setSelectedMarqueeId(id);
        setMarqueeModalVisible(true);
    }

    const handleCancel = () => {
        setMarqueeModalVisible(false);
    }

    return (
        <>
            <div className="home-marquee-container">
                <div className="home-marquee">
                    {/* <img src={AnnouncementIcon} alt="marquee" className="marquee-icon" /> */}
                    <Marquee
                        speed={40}
                        pauseOnHover
                        className='home-marquee-item'
                    >
                        {
                            marqueeData.announcement.map((marquee: {
                                id: string; message: { [x: string]: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; };
                            }) => {
                                return <div key={marquee.id} className="home-marquee-content" onClick={() => { onMarqueeItemClick(marquee.id) }}>
                                    {marquee.message[setDBLanguageToGet(layoutState.locale)] ? marquee.message[setDBLanguageToGet(layoutState.locale)]
                                        : marquee.message[setDBLanguageToGet('en')]}
                                </div>
                            })
                        }
                    </Marquee>
                </div>
            </div>

            <Modal
                className="marquee-modal"
                visible={marqueeModalVisible}
                closable={true}
                footer={null}
                onCancel={handleCancel}
                destroyOnClose
            >
                <img src={Logo} alt={intl.formatMessage({ id: "SEO_LOGO" })} />
                <div className="marquee-modal-title">
                    <FormattedMessage id={'ANNOUNCEMENT'} />
                </div>
                <div>
                    {marqueeData?.announcement?.filter((x: { id: string; }) => x.id === selectedMarqueeId)[0]?.message[setDBLanguageToGet(layoutState.locale)]}
                </div>
            </Modal>
        </>
    )
}

export default MarqueeComponent;