import "./HomeMobile.less";

import HomeMobileMidMenu from "./HomeMobileMidMenu";
import HomeMobileHotGames from "./HomeMobileHotGames";
import { SET_REFERRAL_MODAL_SHOW, useAuth } from "../../../hooks/auth/AuthContext";
import DashboardMobileTop from "../dashboard/dashboard-wallets/DashboardMobileWallets";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_BANNER } from "../../home/Home.gql";
import { Modal } from "antd";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { useEffect, useState } from "react";
import enRefImg from '../../../assets/images/referral-popup-en.png';
import thRefImg from '../../../assets/images/referral-popup-th.png';
import { useNavigate } from "react-router-dom";
import HomeMobileWCCountdown from "./HomeMobileWCCountdown";
import HomeMobileUpcomingMatches from "./HomeMobileUpcomingMatches";
import { GET_HOME_POPUP_BANNER } from "../../../shared/gql/profile.gql";
import { setDBLanguageToGet } from "../../../shared/helpers/language.helpers";
import MobileMarqueeComponent from "./HomeMobileMarquee";
import { FormattedMessage, useIntl } from "react-intl";
import HomeAnonAuth from "./HomeAnonAuth";
import { announcements, gamesProviderLeft, gamesProviderRight } from "../../../shared/components/top-menu/TopMenuList";
import { formatSEOCountry, formatUrlCurrencyLang } from "../../../shared/helpers/general-helpers";
import { Helmet } from "react-helmet";
import HomeMobileBanner from "./HomeMobileBanner";

const HomeMobile = () => {
    const navigate = useNavigate();
    const { authState, authDispatch } = useAuth();
    const [referralModalVisible, setReferralModalVisible] = useState(false);
    const { layoutState } = useLayout();
    // const { data: bannerData } = useQuery(GET_BANNER, { fetchPolicy: 'cache-and-network' });
    const [getBanner, { data: bannerData }] = useLazyQuery(GET_BANNER, { fetchPolicy: 'cache-and-network' });
    const { data } = useQuery(GET_HOME_POPUP_BANNER, { fetchPolicy: 'no-cache' });
    const [homePopUpBannerData, setHomePopUpBannerData] = useState<any>();
    const intl = useIntl();
    const [title, setTitle] = useState(intl.formatMessage({ id: 'SEO_TITLE_HOME' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }));
    const [desc, setDesc] = useState(intl.formatMessage({ id: 'SEO_DESC_HOME' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }));
    const [selectedSubCat, setSelectedSubCat] = useState('sports');
    const allGamesProvider = [...gamesProviderLeft, ...gamesProviderRight];
    const [gameList, setGameList] = useState(allGamesProvider.filter(x => x.gameType === 'sports')[0]);

    useEffect(() => {
        setTitle(intl.formatMessage({ id: 'SEO_TITLE_HOME' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }));
        setDesc(intl.formatMessage({ id: 'SEO_DESC_HOME' }, { 0: intl.formatMessage({ id: formatSEOCountry(authState?.defaultCurrency) }) }))
    }, [localStorage.getItem('pfxl')])

    useEffect(() => {
        if (authState?.defaultCurrency) {
            getBanner({
                variables: { currency: authState?.defaultCurrency }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState?.defaultCurrency]);

    const marqueeData = {
        announcement: [{
            id: '1',
            message: announcements,
        }]
    }

    const closeReferralModal = () => {
        authDispatch({ type: SET_REFERRAL_MODAL_SHOW, payload: false });
        setReferralModalVisible(false);
    }

    useEffect(() => {
        if (authState.showReferralModal) {
            setReferralModalVisible(true);
        }
    }, []);

    useEffect(() => {
        if (authState.showReferralModal) {
            setReferralModalVisible(authState.showReferralModal);
        }
    }, [authState.showReferralModal]);

    useEffect(() => {
        if (data && data?.homePopUpBanner && data?.homePopUpBanner.length > 0) {
            setHomePopUpBannerData(data?.homePopUpBanner[0]);
        }
    }, [data])

    const onReferralClick = () => {
        //setActiveTabKey('9');
        closeReferralModal();
        navigate(`dashboard/profile/referral`)
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={desc} />
            </Helmet>
            <div className="home-mobile-container">
                <HomeMobileBanner bannerData={bannerData} setSelectedSubCat={setSelectedSubCat}
                    setGameList={setGameList} allGamesProvider={allGamesProvider} />
                {!authState || !authState?.isLogin &&
                    <HomeAnonAuth />
                }
                <MobileMarqueeComponent marqueeData={marqueeData} />
                {/* <HomeMobileWCCountdown /> */}
                {authState && authState?.isLogin &&
                    <DashboardMobileTop isFromMainPage={true} />
                }
                {/* <HomeMobileUpcomingMatches showBtn={false} /> */}
                {/* <HomeMobileHotGames /> */}
                {/* <HomeUpcomingMatchesCarousel /> */}
                <HomeMobileMidMenu selectedSubCat={selectedSubCat} setSelectedSubCat={setSelectedSubCat}
                    gameList={gameList} setGameList={setGameList} allGamesProvider={allGamesProvider} />
            </div>
            {
                homePopUpBannerData?.pictureUrl &&
                // homePopUpBannerData?.mobilePictureUrl &&
                <Modal
                    className="m-referral-modal"
                    visible={referralModalVisible}
                    closable={true}
                    footer={null}
                    onCancel={closeReferralModal}
                    destroyOnClose
                >
                    {/* {homePopUpBannerData?.mobilePictureUrl &&
                        <img onClick={onReferralClick} alt='referral' src={homePopUpBannerData?.mobilePictureUrl ?
                            JSON.parse(homePopUpBannerData?.mobilePictureUrl)[setDBLanguageToGet(layoutState.locale)] : enRefImg} /> */}
                    {homePopUpBannerData?.pictureUrl &&
                        <img onClick={onReferralClick} alt='referral' src={homePopUpBannerData?.pictureUrl ?
                            JSON.parse(homePopUpBannerData?.pictureUrl)[setDBLanguageToGet(layoutState.locale)] : enRefImg} />
                    }
                </Modal>
            }
        </>
    );
};

export default HomeMobile;
