
import HomeLiveCasino from './Home-Live-Casino';
import HomeUpcomingMatches from './Home-Upcoming-Matches';
import mobilePhone from '../../../assets/images/mobile-ib8.png';
import mobilePhoneSgd from '../../../assets/images/mobile-ib8-sgd.png';
import { FormattedMessage } from 'react-intl';
import boxItem from '../../../assets/images/boxitem-1.png';
import { useAuth } from '../../../hooks/auth/AuthContext';

const MidSectionCarousels = () => {
    const { authState } = useAuth();

    return (
        <div className="mid-section-carousels-container">
            {/* <HomeUpcomingMatches showBtn={false} /> */}
            {/* <HomeLiveCasino showTitle={true} /> */}
            {/* <HomeLiveCasino showTitle={true} /> */}
            <div className='mid-payment-container'>
                <div className='mid-secure-payment'>
                    <img className='mid-payment-img' src={boxItem} alt={'boxitem'} />
                    <div className='mid-secure-payment-title'><FormattedMessage id='SECURE_PAYMENT' /></div>
                    <div className='mid-secure-payment-content'><FormattedMessage id='SECURE_PAYMENT_DESC' /></div>
                </div>
                <div className='mid-fast-payment'>
                    <img className='mid-payment-img' src={boxItem} alt={'boxitem'} />
                    <div className='mid-fast-payment-title'><FormattedMessage id='FAST_PAYMENT' /></div>
                    <div className='mid-fast-payment-content'><FormattedMessage id='FAST_PAYMENT_DESC' /></div>
                </div>
            </div>
            {authState?.defaultCurrency === 'THB' ?
                <img className='mobile-phone-img' src={mobilePhone} alt={'mobile'} /> :
                <img className='mobile-phone-img' src={mobilePhoneSgd} alt={'mobile'} />
            }
        </div>
    )
}

export default MidSectionCarousels;