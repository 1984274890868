import { Layout } from "antd";
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import Error404Icon from './assets/images/error-404.svg';
import './NoMatch.less';
import { formatUrlCurrencyLang } from "./shared/helpers/general-helpers";


const { Content } = Layout;

const NoMatch = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const home = () => {
        navigate(`/`);
    }

    const contactUs = () => {
        var frame = document.getElementById('chat-widget-minimized') as HTMLIFrameElement;
        if (frame.contentWindow) {
            frame.contentWindow.document.getElementsByTagName('button')[0].click();
        }
    }
    
    return (
        <Layout>
            <Content>
                <Row gutter={16} className="no-match-container">
                    <Col sm={24} md={8} className="">
                        <img width="100%" height="100%" src={Error404Icon} alt="error 404" />
                    </Col>
                    <Col sm={24} md={8} className="error-404-right-wrapper">
                        <h1 className="error-404-title">404</h1>
                        <h2 className="error-404-description"><FormattedMessage id={'ERROR_404_PAGE_NOT_FOUND'} /></h2>
                        <p><FormattedMessage id={'ERROR_404_DESCRIPTION'} /></p>
                        <Row  gutter={16} className="error-404-button-wrapper">
                            <Col sm={24} md={14} ><a className="error-404-button error-404-primary-button" href="#" onClick={home}><FormattedMessage id='ERROR_404_HOME'/></a></Col>
                            <Col sm={24} md={10}><a className="error-404-button error-404-secondary-button" href="#" onClick={contactUs} ><FormattedMessage id='ERROR_404_CONTACT_US'/></a></Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default NoMatch;