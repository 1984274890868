import { gql } from "@apollo/client";

export const REGISTER = gql`
query SignUpCountries{
    signUpCountries{
        id,
        dialCode
    }
}
`

export const USER_SIGNUP = gql`
mutation UserSignUp($registerInput:RegisterInput!) 
{
    userSignUp(registerInput:$registerInput) 
    {
        a,
        r,
        sid,
        status
    }
}
`

export const SIGN_IN = gql`
    mutation SignIn($userName: String!, $password: String!){
        signIn(userName: $userName, password: $password){
            a,
            r,
            sid,
        }
    }
`;

export const LOGOUT = gql`
    mutation Logout{
        logout{
            status
        }
    }
`;

export const CHECK_USERNAME = gql`
    mutation CheckUsername($username: String!,$referralId: String!){
        checkUsername(username: $username,referralId: $referralId){
            status,
            isExist
        }
    }
`;

export const ANON_FORGOT_PASSWORD_REQUEST_CODE = gql`
mutation AnonForgotPasswordRequestCode($userName: String!, $phone:String!, $verificationType:String!){
    anonForgotPasswordRequestCode(userName: $userName, phone:$phone, verificationType:$verificationType){
        status,
    }
}
`;

export const ANON_REGISTER_REQUEST_CODE = gql`
mutation AnonRegisterRequestCode($phone:String!, $verificationType:String!){
    anonRegisterRequestCode(phone:$phone, verificationType:$verificationType){
        status,
    }
}
`;

export const ANON_FORGOT_PASSWORD_SUBMIT = gql`
mutation AnonForgotPasswordSubmit($userName: String!, $phone:String!, $verificationType:String!, $verificationCode:String!){
    anonForgotPasswordSubmit(userName: $userName, phone:$phone, verificationType:$verificationType, verificationCode:$verificationCode){
        status,
    }
}
`;

export const SET_PASSWORD = gql`
mutation setNewPassword($userName: String!, $phone: String!, $password:String!){
    setNewPassword(userName: $userName,phone: $phone, password:$password){
        status,
    }
}
`
export const GET_CONFIGURATIONS = gql`
query Configurations($config: String!){
    configurations(config: $config){
        id,
        value
    }
}
`;