import { FormattedMessage, useIntl } from "react-intl";
import LineQR from '../../../assets/images/line-qr-code-new.png';
import wechatQR from '../../../assets/images/wechat-qr.jpg'

import csIcon from '../../../assets/images/icon-sm-phone.png';
import weChatIcon2 from '../../../assets/images/icon-sm-wechat.png';
import telegramIcon2 from '../../../assets/images/icon-sm-telegram2.png';
import whatsappIcon2 from '../../../assets/images/icon-sm-whatsapp.png';
import livechatIcon2 from '../../../assets/images/live-chat.png';
import lineIcon2 from '../../../assets/images/icon-sm-line.png';
import emailIcon from '../../../assets/images/icon-email.png';
import discordIcon from '../../../assets/images/icon-sm-discord.png';
import viberIcon from '../../../assets/images/viber_logo.png';
import { useAuth } from '../../../hooks/auth/AuthContext';
import { capitalize } from "lodash";


const StickyContainer = () => {
    const { authState } = useAuth();
    const intl = useIntl();
    const thbLineQrLink = process.env.REACT_APP_LINE;
    const sgdWhatsAppQrLink = process.env.REACT_APP_WHATSAPP;
    const sgdWhatsAppQrLink2 = process.env.REACT_APP_WHATSAPP2;
    const sgdTelegramQrLink = process.env.REACT_APP_TELEGRAM_SGD;
    const sgdLiveChatLink = process.env.REACT_APP_LIVECHAT_SGD;
    const thbLiveChatLink = process.env.REACT_APP_LIVECHAT_THB;
    const thbLEmailLink = process.env.REACT_APP_EMAIL_THB;
    const discordLink = process.env.REACT_APP_DISCORD;
    const phpLiveChatLink = process.env.REACT_APP_LIVECHAT_PHP
    const phpWhatsAppLink = process.env.REACT_APP_WHATSAPP_PHP
    const phpTelegramQrLink = process.env.REACT_APP_TELEGRAM_PHP
    const phpViberLink = process.env.REACT_APP_VIBER_PHP
    const phpEmailLink = process.env.REACT_APP_EMAIL_PHP
    const phpDiscordLink = process.env.REACT_APP_DISCORD_PHP

    const onCsClick = (link: any, type?: string) => {
        if (link) {
            if (type && type === 'email') {
                window.open(`mailto:${link}`)
            } else {
                window.open(link);
            }
        }
    }

    return (
        <div className='cs-stick'>
            <div className='cs-stick-icon'>
                <img className='cs-img' src={csIcon} alt="cs icon" />
                <div className='cs-txt'><FormattedMessage id='CONTACT_US_TITLE' /></div>
            </div>
            <div className='cs-stick-container'>
                {authState?.defaultCurrency === 'THB' ?
                    <>
                        <div className='stick-item' onClick={() => { onCsClick(thbLiveChatLink) }}>
                            <div className='stick-item-icon'>
                                <img className='stick-icon' src={livechatIcon2} alt='livechat' />
                            </div>
                            <div className='stick-label'>
                                <div className='stick-label-txt'><FormattedMessage id='LIVE_CHAT' /></div>
                            </div>
                        </div>
                        <div className='stick-item' onClick={() => { onCsClick(thbLEmailLink, 'email') }}>
                            <div className='stick-item-icon'>
                                <img className='stick-icon' src={emailIcon} alt='livechat' />
                            </div>
                            <div className='stick-label'>
                                <div className='stick-label-txt'>{capitalize(intl.formatMessage({ id: "DASHBOARD_REFERRAL_EMAIL" }))}</div>
                            </div>
                        </div>
                        <div className='stick-item' onClick={() => { onCsClick(thbLineQrLink) }}>
                            <div className='stick-item-icon'>
                                <img className='stick-icon' src={lineIcon2} alt='line' />
                            </div>
                            <div className='stick-label'>
                                <div className='stick-label-txt'>{process.env.REACT_APP_LINE_ID_THB ?? ''}</div>
                            </div>
                        </div>
                        <div className='stick-item' onClick={() => { onCsClick(discordLink) }}>
                            <div className='stick-item-icon'>
                                <img className='stick-icon' src={discordIcon} alt='line' />
                            </div>
                            <div className='stick-label'>
                                <div className='stick-label-txt'>
                                    <FormattedMessage id='DASHBOARD_REFERRAL_DISCORD' />
                                </div>
                            </div>
                        </div>
                        <div className='stick-item' onClick={() => { onCsClick(thbLineQrLink) }}>
                            <div className='stick-item-icon'>
                                <img className='stick-icon' src={lineIcon2} alt='line' />
                            </div>
                            <div className='stick-label'>
                                <img className='qr-img' src={LineQR} alt='line-qr' />
                            </div>
                        </div>
                    </>
                    : authState?.defaultCurrency === 'PHP' ?
                        <>
                            <div className='stick-item' onClick={() => { onCsClick(phpLiveChatLink) }}>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={livechatIcon2} alt='livechat' />
                                </div>
                                <div className='stick-label'>
                                    <div className='stick-label-txt'><FormattedMessage id='LIVE_CHAT' /></div>
                                </div>
                            </div>
                            <div className='stick-item' onClick={() => { onCsClick(phpWhatsAppLink) }}>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={whatsappIcon2} alt='line' />
                                </div>
                                <div className='stick-label'>
                                    <div className='stick-label-txt'>{process.env.REACT_APP_WA_PHP ?? ''}</div>
                                </div>
                            </div>
                            <div className='stick-item' onClick={() => { onCsClick(phpTelegramQrLink) }}>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={telegramIcon2} alt='line' />
                                </div>
                                <div className='stick-label'>
                                    <div className='stick-label-txt'>{process.env.REACT_APP_WA_PHP ?? ''}</div>
                                </div>
                            </div>
                            <div className='stick-item' >
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={viberIcon} alt='line' />
                                </div>
                                <div className='stick-label'>
                                    <div className='stick-label-txt'>{process.env.REACT_APP_WA_PHP ?? ''}</div>
                                </div>
                            </div>
                            <div className='stick-item' onClick={() => { onCsClick(phpEmailLink, 'email') }}>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={emailIcon} alt='livechat' />
                                </div>
                                <div className='stick-label'>
                                    <div className='stick-label-txt'>{capitalize(intl.formatMessage({ id: "DASHBOARD_REFERRAL_EMAIL" }))}</div>
                                </div>
                            </div>
                            <div className='stick-item' onClick={() => { onCsClick(phpDiscordLink) }}>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={discordIcon} alt='line' />
                                </div>
                                <div className='stick-label'>
                                    <div className='stick-label-txt'>
                                        <FormattedMessage id='DASHBOARD_REFERRAL_DISCORD' />
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className='stick-item' onClick={() => { onCsClick(sgdLiveChatLink) }}>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={livechatIcon2} alt='livechat' />
                                </div>
                                <div className='stick-label'>
                                    <div className='stick-label-txt'><FormattedMessage id='LIVE_CHAT' /></div>
                                </div>
                            </div>
                            <div className='stick-item' onClick={() => { onCsClick(sgdWhatsAppQrLink) }}>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={whatsappIcon2} alt='line' />
                                </div>
                                <div className='stick-label'>
                                    <div className='stick-label-txt'>{process.env.REACT_APP_WA_SGD ?? ''}</div>
                                </div>
                            </div>
                            <div className='stick-item' onClick={() => { onCsClick(sgdWhatsAppQrLink2) }}>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={whatsappIcon2} alt='line' />
                                </div>
                                <div className='stick-label'>
                                    <div className='stick-label-txt'>{process.env.REACT_APP_WA2_SGD ?? ''}</div>
                                </div>
                            </div>
                            <div className='stick-item' onClick={() => { onCsClick(sgdTelegramQrLink) }}>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={telegramIcon2} alt='line' />
                                </div>
                                <div className='stick-label'>
                                    <div className='stick-label-txt'>{process.env.REACT_APP_WECHAT_ID_SGD ?? ''}</div>
                                </div>
                            </div>
                            <div className='stick-item'>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={weChatIcon2} alt='line' />
                                </div>
                                <div className='stick-label' style={{ color: "black" }}>
                                    <div className='stick-label-txt'>{process.env.REACT_APP_WECHAT_ID_SGD ?? ''}</div>
                                    {/* <div className='stick-coming-soon'><FormattedMessage id='COMING_SOON' /></div> */}
                                </div>
                            </div>
                            <div className='stick-item' onClick={() => { onCsClick(discordLink) }}>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={discordIcon} alt='line' />
                                </div>
                                <div className='stick-label'>
                                    <div className='stick-label-txt'>
                                        <FormattedMessage id='DASHBOARD_REFERRAL_DISCORD' />
                                    </div>
                                </div>
                            </div>
                            <div className='stick-item'>
                                <div className='stick-item-icon'>
                                    <img className='stick-icon' src={weChatIcon2} alt='wechat' />
                                </div>
                                <div className='stick-label'>
                                    <img className='qr-img' src={wechatQR} alt='wechat-qr' />
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    );
}

export default StickyContainer;