
import "../Auth.less";

// import benefitSgdEn from '../../../assets/images/member-benefits-sgd-en.jpg';
// import benefitSgdCn from '../../../assets/images/member-benefits-sgd-cn.jpg';
// import benefitThbEn from '../../../assets/images/member-benefits-thb-en.jpg';
// import benefitThbTh from '../../../assets/images/member-benefits-thb-th.jpg';

// import kolSgdEn from '../../../assets/images/kol-sgd-en.jpg';
// import kolSgdCn from '../../../assets/images/kol-sgd-cn.jpg';
// import kolThbEn from '../../../assets/images/kol-thb-en.jpg';
// import kolThbTh from '../../../assets/images/kol-thb-th.jpg';

// import welcomeSgdEn from '../../../assets/images/welcome-sgd-en.jpg';
// import welcomeSgdCn from '../../../assets/images/welcome-sgd-cn.jpg';
// import welcomeThbEn from '../../../assets/images/welcome-thb-en.jpg';
// import welcomeThbTh from '../../../assets/images/welcome-thb-th.jpg';
import { useEffect, useState } from "react";
import { useLayout } from "../../../hooks/layout/LayoutContext";
import { useAuth } from "../../../hooks/auth/AuthContext";
import VideoCard from "../../home/home-components/Video-Card";
import registerStepBanner from '../../../assets/images/register-steps-sg.jpg';
import registerStepBannerTh from '../../../assets/images/register-steps-th.jpg';
import { GET_CONFIGURATIONS } from "../auth.gql";
import { useLazyQuery } from "@apollo/client";
import _ from "lodash";

const RegisterBanners = () => {
    const { layoutState } = useLayout();
    const { authState } = useAuth();
    // const [benefitBanner, setBenefitBanner] = useState(benefitThbEn);
    // const [kolBanner, setKolBanner] = useState(kolThbEn);
    // const [welcomeBanner, setWelcomeBanner] = useState(welcomeThbEn);
    const [getSideBanners, { data: getData }] = useLazyQuery(GET_CONFIGURATIONS, { fetchPolicy: "cache-and-network" });
    const [sideBanner, setSideBanner] = useState<any>();

    useEffect(() => {
        if (layoutState.locale && authState?.defaultCurrency) {
            const localFromStorage = localStorage.getItem('pfxl') ?? 'en';
            
            getSideBanners({
                variables: {
                    config: `registerSideBanner${_.capitalize(authState?.defaultCurrency?.toLowerCase())}${localFromStorage}`,
                }
            })
            // if (authState?.defaultCurrency.toLowerCase() === 'sgd') {
            //     if (layoutState.locale === 'en') {
            //         setBenefitBanner(benefitSgdEn);
            //         setKolBanner(kolSgdEn);
            //         setWelcomeBanner(welcomeSgdEn);
            //     } else {
            //         setBenefitBanner(benefitSgdCn);
            //         setKolBanner(kolSgdCn);
            //         setWelcomeBanner(welcomeSgdCn);
            //     }
            // } else {
            //     if (layoutState.locale === 'en') {
            //         setBenefitBanner(benefitThbEn);
            //         setKolBanner(kolThbEn);
            //         setWelcomeBanner(welcomeThbEn);
            //     } else {
            //         setBenefitBanner(benefitThbTh);
            //         setKolBanner(kolThbTh);
            //         setWelcomeBanner(welcomeThbTh);
            //     }
            // }
        }
    }, [layoutState.locale, authState?.defaultCurrency])

    useEffect(()=>{
        if(getData && getData?.configurations) {
            setSideBanner(getData?.configurations?.value)
        }
    },[getData])

    return (
        <div className="register-banners-container">
            {/* <div className="benefits-container">
                {benefitBanner &&
                    <img src={benefitBanner} alt='benefits' />
                }
            </div> */}
            <div className="video-container">
                <VideoCard />
            </div>
            {window.innerWidth > 760 &&
                <div className="register-step-banner">
                    {/* {authState?.defaultCurrency === 'THB' ? */}
                        <img src={sideBanner ?? registerStepBanner} alt='step' /> :
                         {/* <img src={registerStepBanner} alt='step' />
                     } */}
                </div>
            }
            {/* {window.innerWidth > 760 &&
                <>
                    <div className="kol-container">
                        {kolBanner &&
                            <img src={kolBanner} alt='benefits' />
                        }
                    </div>
                    <div className="welcome-container">
                        {welcomeBanner &&
                            <img src={welcomeBanner} alt='benefits' />
                        }
                    </div>
                </>
            } */}
        </div>
    );
};

export default RegisterBanners;
