
import '../../DashboardMobile.less';

import iconProfile from '../../../../../assets/images/icon-profile-white.svg';
import { useAuth } from '../../../../../hooks/auth/AuthContext';
import { useQuery } from '@apollo/client';
import { GET_UNREAD_MESSAGE } from '../../../dashboard_v2/components/messages/DashboardMobileMessages.gql';
import { useEffect, useState } from 'react';

const DashboardMobileTopProfile = () => {
    const { authState } = useAuth();
    const { data } = useQuery(GET_UNREAD_MESSAGE, { fetchPolicy: 'network-only' });
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);

    useEffect(() => {
        if (data && data.unreadMessageCount) {
            setUnreadMessageCount(+data.unreadMessageCount.infoUnread + +data.unreadMessageCount.paymentUnread + +data.unreadMessageCount.systemUnread);
        }
    }, [data])

    return (
        <div className="m-dashboard-wallets-profile-container">
            {authState?.userProfile?.userName &&
                <div className='m-profile'>
                    <div className='profile-icon-indicator'>
                        <img src={iconProfile} alt="user" className="m-user-icon" />
                        {unreadMessageCount > 0 &&
                            <div className='indicator'>{unreadMessageCount}</div>
                        }
                    </div>
                    <div className='m-dashboard-wallets-profile-name'>{authState?.userProfile?.userName}</div>
                </div>
            }
        </div>
    )
}

export default DashboardMobileTopProfile;
