
import "../Home.less";
import VideoCard from "./Video-Card";
import MidSectionCarousels from "./Mid-Section-Carousels";

const HomeMidSection = () => {
    return (
        <div className="home-mid-section-container">
            <div className="home-mid-section-left">
                <VideoCard />
            </div>
            <div className="home-mid-section-right">
                <MidSectionCarousels />
            </div>
        </div>
    );
};

export default HomeMidSection;
