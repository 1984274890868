import './VpnDialog.less';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import iconWarning from '../../../assets/images/icon-error-message.png';

interface Props {
    vpnDialogVisible: any;
    setVpnDialogVisible: any;
}

const VpnDialog = ({ vpnDialogVisible, setVpnDialogVisible }: Props) => {

    return (
        <>
            <Modal
                className={'vpn-modal'}
                visible={vpnDialogVisible}
                closable={false}
                footer={null}
                onCancel={() => setVpnDialogVisible(false)}
                destroyOnClose
            >
                <div className="vpn-title-container">
                    <img alt='warn' src={iconWarning} />
                    <div className='vpn-title'><FormattedMessage id='VPN_REQUIRED' /></div>
                </div>
                <div className='vpn-back-container'>
                    <div className='vpn-back' onClick={() => { setVpnDialogVisible(false) }}>
                        <FormattedMessage id='VPN_GO_BACK' />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default VpnDialog;