import MoreGames1 from '../../../assets/images/hotgames-pg1.png';
import MoreGames2 from '../../../assets/images/hotgames-pg2.png';
import MoreGames3 from '../../../assets/images/hotgames-pg3.png';
import MoreGames4 from '../../../assets/images/hotgames-pg4.png';
import MoreGames5 from '../../../assets/images/hotgames-pg5.png';
import MoreGames6 from '../../../assets/images/hotgames-pg6.png';

import SgdMoreGames1 from '../../../assets/images/game-thumbnails-1.jpg';
import SgdMoreGames2 from '../../../assets/images/game-thumbnails-2.jpg';
import SgdMoreGames3 from '../../../assets/images/game-thumbnails-6.png';
import SgdMoreGames4 from '../../../assets/images/game-thumbnails-7.png';
import SgdMoreGames7 from '../../../assets/images/game-thumbnails-3.png';
import SgdMoreGames8 from '../../../assets/images/game-thumbnails-4.jpg';
import SgdMoreGames5 from '../../../assets/images/game-thumbnails-8.jpg';
import SgdMoreGames6 from '../../../assets/images/game-thumbnails-9.jpg';

import Casino1 from '../../../assets/images/live-casino-1.jpg';
import Casino2 from '../../../assets/images/live-casino-2.jpg';
import Casino3 from '../../../assets/images/live-casino-3.jpg';
import Casino4 from '../../../assets/images/live-casino-4.jpg';
import Casino5 from '../../../assets/images/live-casino-5.jpg';
import CasinoYeeBet from '../../../assets/images/live-casino-yeebet.jpg';
import CasinoRoyal from '../../../assets/images/live-casino-royal.jpg';

export const thbMoreGameList = [
    // {
    //     id: '1',
    //     name: 'Leprechaun Riches',
    //     imgUrl: MoreGames1,
    //     gameCode: 'PG|29|leprechaun-riches',
    //     providerId: 'PGSLOT',
    //     providerName: 'PG Soft'
    // },
    // {
    //     id: '2',
    //     name: 'Mahjong Ways',
    //     imgUrl: MoreGames2,
    //     gameCode: 'PG|29|mahjong-ways',
    //     providerId: 'PGSLOT',
    //     providerName: 'PG Soft'
    // },
    // {
    //     id: '3',
    //     name: 'Mahjong Ways 2',
    //     imgUrl: MoreGames3,
    //     gameCode: 'PG|29|mahjong-ways2',
    //     providerId: 'PGSLOT',
    //     providerName: 'PG Soft'
    // },
    // {
    //     id: '4',
    //     name: 'Treasures of Aztec',
    //     imgUrl: MoreGames4,
    //     gameCode: 'PG|29|treasures-aztec',
    //     providerId: 'PGSLOT',
    //     providerName: 'PG Soft'
    // },
    // {
    //     id: '5',
    //     name: 'Lucky Neko',
    //     imgUrl: MoreGames5,
    //     gameCode: 'PG|29|lucky-neko',
    //     providerId: 'PGSLOT',
    //     providerName: 'PG Soft'
    // },
    // {
    //     id: '6',
    //     name: 'The Great Icecape',
    //     imgUrl: MoreGames6,
    //     gameCode: 'PG|29|the-great-icescape',
    //     providerId: 'PGSLOT',
    //     providerName: 'PG Soft'
    // },
    {
        id: '1',
        name: 'Fiery Sevens Exclusive',
        imgUrl: SgdMoreGames1,
        gameCode: 'S-FS02',
        providerId: 'SPADEGAMING',
        providerName: 'Spade Gaming',
        rtp: "96.52"
    },
    {
        id: '2',
        name: '888',
        imgUrl: SgdMoreGames2,
        gameCode: 'S-BA01',
        providerId: 'SPADEGAMING',
        providerName: 'Spade Gaming',
        rtp: "96.09"
    },
    {
        id: '3',
        name: 'Super Ace',
        imgUrl: SgdMoreGames3,
        gameCode: 'SLOT|JILI-SLOT-027',
        providerId: 'JILI',
        providerName: 'Jili',
        rtp: "97.00"
    },
    {
        id: '4',
        name: 'Golden Empire',
        imgUrl: SgdMoreGames4,
        gameCode: 'SLOT|JILI-SLOT-042',
        providerId: 'JILI',
        providerName: 'Jili',
        rtp: "97.00"
    },
    {
        id: '5',
        name: 'Funky Bingo',
        imgUrl: SgdMoreGames5,
        gameCode: 'GB14',
        providerId: 'CQ9',
        providerName: 'CQ9',
        rtp: "96.04"
    },
    // {
    //     id: '6',
    //     name: 'Treasure Koi',
    //     imgUrl: SgdMoreGames6,
    //     gameCode: 'AR81',
    //     providerId: 'CQ9',
    //     providerName: 'CQ9',
    //     rtp: "94.03"
    // },
    {
        id: '7',
        name: 'Sweet Bonanza',
        imgUrl: SgdMoreGames7,
        gameCode: 'vs20fruitsw',
        providerId: 'PRAGMATICPLAY',
        providerName: 'Pragmatic Play',
        rtp: "96.50"
    },
    {
        id: '8',
        name: 'Gates of Olympus',
        imgUrl: SgdMoreGames8,
        gameCode: 'vs20olympgate',
        providerId: 'PRAGMATICPLAY',
        providerName: 'Pragmatic Play',
        rtp: "96.50"
    },
]

export const sgdMoreGameList = [
    {
        id: '1',
        name: 'Fiery Sevens Exclusive',
        imgUrl: SgdMoreGames1,
        gameCode: 'S-FS02',
        providerId: 'SPADEGAMING',
        providerName: 'Spade Gaming',
        rtp: "96.52"
    },
    {
        id: '2',
        name: '888',
        imgUrl: SgdMoreGames2,
        gameCode: 'S-BA01',
        providerId: 'SPADEGAMING',
        providerName: 'Spade Gaming',
        rtp: "96.09"
    },
    {
        id: '3',
        name: 'Super Ace',
        imgUrl: SgdMoreGames3,
        gameCode: 'SLOT|JILI-SLOT-027',
        providerId: 'JILI',
        providerName: 'Jili',
        rtp: "97.00"
    },
    {
        id: '4',
        name: 'Golden Empire',
        imgUrl: SgdMoreGames4,
        gameCode: 'SLOT|JILI-SLOT-042',
        providerId: 'JILI',
        providerName: 'Jili',
        rtp: "97.00"
    },
    {
        id: '5',
        name: 'Funky Bingo',
        imgUrl: SgdMoreGames5,
        gameCode: 'GB14',
        providerId: 'CQ9',
        providerName: 'CQ9',
        rtp: "96.04"
    },
    // {
    //     id: '6',
    //     name: 'Treasure Koi',
    //     imgUrl: SgdMoreGames6,
    //     gameCode: 'AR81',
    //     providerId: 'CQ9',
    //     providerName: 'CQ9',
    //     rtp: "94.03"
    // },
    {
        id: '7',
        name: 'Sweet Bonanza',
        imgUrl: SgdMoreGames7,
        gameCode: 'vs20fruitsw',
        providerId: 'PRAGMATICPLAY',
        providerName: 'Pragmatic Play',
        rtp: "96.50"
    },
    {
        id: '8',
        name: 'Gates of Olympus',
        imgUrl: SgdMoreGames8,
        gameCode: 'vs20olympgate',
        providerId: 'PRAGMATICPLAY',
        providerName: 'Pragmatic Play',
        rtp: "96.50"
    },
]


export const thbCasinoList = [
    {
        id: '1',
        name: 'Baccarat',
        imgUrl: Casino1,
        gameCode: 'SAGAMING',
        providerId: 'SAGAMING',
        providerName: 'SA Gaming'
    },
    {
        id: '2',
        name: 'Baccarat',
        imgUrl: Casino2,
        gameCode: 'SEXYBACARRAT',
        providerId: 'SEXYBACARRAT',
        providerName: 'Sexy'
    },
    {
        id: '3',
        name: 'Roulette',
        imgUrl: Casino3,
        gameCode: 'EVOLUTION',
        providerId: 'EVOLUTION',
        providerName: 'Evolution'
    },
    {
        id: '4',
        name: 'Roulette',
        imgUrl: CasinoYeeBet,
        gameCode: 'YEEBETLIVECASINO',
        providerId: 'YEEBET',
        providerName: 'Yeebet'
    },
    {
        id: '5',
        name: 'Sic Bo',
        imgUrl: Casino5,
        gameCode: 'WMLIVECASINO',
        providerId: 'WM',
        providerName: 'WM'
    },
    {
        id: '6',
        name: 'Sic Bo',
        imgUrl: CasinoRoyal,
        gameCode: 'ROYAL',
        providerId: 'ROYAL',
        providerName: 'Royal'
    }
]

export const sgdCasinoList = [
    {
        id: '1',
        name: 'Baccarat',
        imgUrl: Casino1,
        gameCode: 'SAGAMING',
        providerId: 'SAGAMING',
        providerName: 'SA Gaming'
    },
    {
        id: '2',
        name: 'Baccarat',
        imgUrl: Casino2,
        gameCode: 'SEXYBACARRAT',
        providerId: 'SEXYBACARRAT',
        providerName: 'Sexy'
    },
    {
        id: '3',
        name: 'Roulette',
        imgUrl: CasinoYeeBet,
        gameCode: 'YEEBETLIVECASINO',
        providerId: 'YEEBETLIVECASINO',
        providerName: 'Yeebet'
    },
    {
        id: '4',
        name: 'Sic Bo',
        imgUrl: Casino5,
        gameCode: 'WMLIVECASINO',
        providerId: 'WM',
        providerName: 'WM'
    },
    {
        id: '5',
        name: 'Sic Bo',
        imgUrl: CasinoRoyal,
        gameCode: 'ROYAL',
        providerId: 'ROYAL',
        providerName: 'Royal'
    }
]
