export const getHeaderType = (location: string) => {
    if (location === '/home' || location === '/home/' || location === '/promotions') {
        return 'HOME';
    } else {
        switch (location) {
            case '/home/dashboard/banking':
                return 'M_DASHBOARD_GENERAL_BANKING';
            case '/home/dashboard/banking/deposit':
                return 'M_DASHBOARD_DEPOSIT';
            case '/home/dashboard/banking/transfer':
                return 'M_DASHBOARD_TRANSFER';
            case '/home/dashboard/banking/withdrawal':
                return 'M_DASHBOARD_WITHDRAWAL';
            case '/home/dashboard/banking/history':
                return 'M_DASHBOARD_HISTORY';
            case '/home/dashboard/profile':
                return 'M_DASHBOARD_GENERAL_PROFILE';
            case '/home/dashboard/profile/my-profile':
                return 'M_DASHBOARD_PROFILE';
            case '/home/dashboard/profile/messaging':
                return 'M_DASHBOARD_MESSAGING';
            case '/home/dashboard/profile/change-password':
                return 'M_DASHBOARD_CHANGE_PASSWORD';
            case '/home/dashboard/profile/referral':
                return 'M_DASHBOARD_REFERRAL';
            default:
                return 'HOME';
        }
    }

    return '';
}